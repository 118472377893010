import { Col, Form, Input, message, Row } from "antd";
import Logo from "../../assets/backendassets/logo.png";
import { BiLogoPlayStore } from "react-icons/bi";
import { FaApple } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { PiArrowBendRightDownBold } from "react-icons/pi";
import logo from'../../assets/backendassets/logo.png'
import { Link } from "react-router-dom";
import { useState } from "react";
import { newsletter } from "../../utils/API";
import { Overlay } from "antd/es/popconfirm/PurePanel";
import { PhoneOutlined } from '@ant-design/icons';

const Footeruser = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubscribe = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await newsletter(values);
      if (response.status === 200) {
        form.resetFields();
        message.success("Subscribed successfully!");
      }
    } catch (error) {
      message.error("Subscription failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <div
        className="p-4 md:p-0 "
   style={{
    backgroundImage:
     "linear-gradient(rgba(17, 22, 31, 0.8), rgba(17, 22, 31, 0.8)), url(https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/bg-footer.png)",
    backgroundSize: "cover",
    backgroundColor: "#11161F",
    height: "auto",
    width: "100%",
    backgroundPosition: "bottom left",
  }}
      >
        <Row className="md:pt-8 md:ps-1 md:px-2 xl:pt-16  xl:ps-8 xl:px-12   sm:pt-8 sm:ps-8 sm:px-8  xs:pt-8 xs:ps-8 xs:px-8 ">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
            className="text-white mx-auto"
          >
            <img
              src={logo}
              alt="Logo"
              className="mb-3 mt-2 hover:logo-yellow-700 mr-[30px]"
              style={{ width: "180px"}}
            />
            <p className="  pb-8 footer-logo-font pr-24 mr-[30px]" style={{ fontFamily: '"Manrope", sans-serif' }}>
            We helps SMBs and enterprises leverage digital technology to simplify their business and grow online.
            </p>  
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={3} xxl={3} className=" md:pl-0 md:pr-0 flex flex-col items-start md:items-start md:right-[100px] mx-auto ">
            <p className="text-2xl font-bold text-white"style={{ fontFamily: '"Manrope", sans-serif' }}> Explore</p>
            <Link to="/mlm/login" className="footer-explore-font mt-4  mb-1 d-flex flex-row align-items-center hover:text-yellow-700">
            Rapidexie login
            </Link>
            <Link to="/about-us" className="footer-explore-font mt-4  mb-1 d-flex flex-row align-items-center  hover:text-yellow-700">
              About us 
            </Link>
            <Link to="/backend/profile" className="footer-explore-font  mt-4 mb-1 d-flex flex-row align-items-center hover:text-yellow-700">
              My Account 
            </Link>
            <Link to="/listing" className="footer-explore-font  mt-4  mb-1 d-flex flex-row align-items-center hover:text-yellow-700">
              All Listing
            </Link>
            <Link to="/contact-us" className="footer-explore-font mt-4  mb-1 d-flex flex-row align-items-center hover:text-yellow-700">
              Contact Us
            </Link>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={5} xxl={5} className="md:pl-4 md:pr-0 flex flex-col items-start md:items-start md:right-[90px] mx-auto">
            <p className="pl-2 text-2xl font-bold text-white"style={{ fontFamily: '"Manrope", sans-serif' }}> Contact</p>
            <a className="text-gray-400 mt-3 font-semibold mb-2 d-flex flex-row align-items-center">
              <div>
                <FaLocationDot
                  className=" mb-10 rounded-full bg-yellow-700"
                  size={43}
                  style={{
                    color: "black",
                    padding: "12px",
                  }}
                />
              </div>{" "}
              <div className="pl-2 flex flex-col hover:text-yellow-700 max-w-sm">
                {" "}
                <div className="mt-2 mb-2 footer-font ">Locations</div>
                <div className="footer-font-description font-bold"><p style={{ fontFamily: '"Manrope", sans-serif' }}>D-9, Vyapar Marg, Block D, Noida Sector 3, Noida, Uttar Pradesh 201301</p></div>
              </div>  
            </a>
            <a className="text-gray-400 mt-2 font-semibold mb-2 d-flex flex-row align-items-center">
              <div>
                <IoIosMail
                  className="mb-4 rounded-full bg-yellow-700"
                  size={43}
                  style={{
                    color : "black",
                    padding : "12px",
                  }}
                />
              </div>{" "}
              <div className="pl-2 flex flex-col hover:text-yellow-700">
                {" "}
                <div className=" mb-2 footer-font">Send Mail</div>
                <div className="footer-font-description  font-bold"><p 
                style={{ fontFamily: '"Manrope", sans-serif' }}>contact@net4surge.com</p></div>
              </div>
            </a>
            <a className="text-gray-400 mt-2 font-semibold mb-2 d-flex flex-row align-items-center ">
              <div>
              <PhoneOutlined
                  className=" mb-4 bg-yellow-700 rounded-full"
                  size={43}
                  style={{
                    color: "black",
                    padding: "12px",
                  }}
                />
              </div>{" "}
              <div className="pl-2 flex flex-col hover:text-yellow-700">
                {" "}
                <div className="mb-2 footer-font">Call Anytime</div>
                <div className="footer-font-description  font-bold">(+91)9818755350</div>
              </div>
            </a>
          </Col>
          <Col xs={24} sm={24} md={11} lg={14} xl={7} xxl={8}  className="text-white  justify-around flex">
            <div
              className="p-8 "
              style={{ backgroundColor: "#191f29", borderRadius: "10px" }}
            >
              <p className="font-bold text-lg text-white d-flex" style={{ fontFamily: '"Manrope", sans-serif' }}>
                NEWSLETTER{" "}
                <PiArrowBendRightDownBold
                  size={30}
                  className="pl-2 mt-2 text-yellow-700"
                />{" "}
              </p>
              <p className="text-md font-newsletter mt-2 mb-2"style={{ fontFamily: '"Manrope", sans-serif' }}>
                Subscribe to our newsletter to get our daily latest news and
                updates.
              </p>
            <Form form={form} onFinish={handleSubscribe}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                 <Input
                size="large"
                className="mt-4 rounded-md border-0 p-3"
                placeholder="Email address "
              />
              </Form.Item>
              <button
                className="btn bt-lg mt-4 font-bold pl-8 pr-8 pt-3 pb-3 text-sm bg-yellow-700"
                style={{color: "white", fontFamily: '"Manrope", sans-serif' }}
              >
                Subscribe now
              </button>
            </Form>
            </div>
          </Col>
        </Row>
      </div>
      {/* <footer className="bg-black text-white py-6">
            <div className="container mx-auto text-center">
                <p className="text-sm">
                    &copy; 2024 Your Company. All rights reserved.
                </p>
                <div className="flex justify-center space-x-4 mt-2">
                </div>
            </div> */}
        {/* </footer> */}
    </> 
  );
};
export default Footeruser;
