import React from 'react'
import { Row, Col } from 'antd';
import { TbDiscount2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import image from "../../../assets/frontendassets/websiteimages/SECTION8whatnet4surgeoffer.jpg";


const LestinOffer: React.FC = () => {
  const navigate = useNavigate();
  
const handleEnquiryRedirect = () => {
  navigate("/listing?category=&state=");
};
  return (
    <>
    <div className='bg-gray-100'>
  <Row  className="mt-5">
  <Col xs={24} sm={24} md={24} lg={10} xl={12} xxl={12} className='pt-6 md:pt-0 pb-6 md:pb-24 pl-4 xl:pl-48 mt-6'>
    <p className='font-semibold text-lg text-md mb-3 tracking-widest text-yellow'style={{ fontFamily: '"Manrope", sans-serif' }}  >What Net4Surge Offers</p>
    <p className='text-5xl font-extrabold tracking-tight'style={{ fontFamily: '"Manrope", sans-serif' }}>Grow your business with our platform</p>
    <p className='pt-3 text-lg text-gray-500'style={{ fontFamily: '"Manrope", sans-serif' }}>At Net4Surge, we provide a comprehensive suite of services designed to elevate your business and ensure your success:</p>
    <span className="bg-white d-flex justify-between p-4 mt-4 mb-4 border-r-4 border-yellow-700" style={{ backgroundColor: "#f5f5f5" }}>
  <div className="font-bold text-lg text-yellow"style={{ fontFamily: '"Manrope", sans-serif' }}>
    Join Net4Surge today and take advantage of these offerings to propel your business to new heights!
  </div>
</span>

    <div className='d-flex align-items-center'>
      <TbDiscount2 className='text-yellow w-20 h-20 font-light' />
      <p className='text-black tracking-wider font-bold text-xl pl-2'style={{ fontFamily: '"Manrope", sans-serif' }}>Find the best places in the city for business, activities and relaxation</p>
    </div>
    <button className=" font-bold px-14 py-3 rounded-md  mt-5 text-black bg-yellow-700" 
         onClick={handleEnquiryRedirect} style={{ fontFamily: '"Manrope", sans-serif' }}>
                Search Now
              </button>
  </Col>
  <Col xs={24} sm={24} md={24} lg={14} xl={12} xxl={12} className='pt-6 md:block xl:block lg:block md:pt-0   pb-6 sm:hidden xs:hidden  pl-6 md:pl-24 xs:pt-6 '>
    <img src={image} className=' w-full' style={{ maxWidth : "100%", backgroundPosition : "center" ,  }} alt="Family spending time together outdoors" />
  </Col>
</Row>
</div>
    </>
  )
}

export default LestinOffer