
import React, { useContext, useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
  Steps,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import Dragger from "antd/es/upload/Dragger";
import {
  BiCross,
  BiMinus,
  BiMinusCircle,
  BiPlus,
  BiSolidInbox,
} from "react-icons/bi";
import { CloseOutlined } from "@ant-design/icons";
import img1 from "../../../../assets/frontendassets/Form/Frame_1.jpg";
import img2 from "../../../../assets/frontendassets/Form/Frame_2.jpg";
import img3 from "../../../../assets/frontendassets/Form/Frame_3.jpg";
import img4 from "../../../../assets/frontendassets/Form/Frame_5.jpg";
import "../business/Listing.css";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Upload, { UploadChangeParam, UploadFile } from "antd/es/upload";
import { BsUpload } from "react-icons/bs";
import { AuthContext } from "../../../Auth/AuthContext";
import {
  BusinessUpdate,
  deleteimage,
  EditBusiness,
  getAllcategories,
} from "../../../../utils/API";
import { RcFile } from "antd/es/upload";
import axios from "axios";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
const { Option } = Select;
const { Step } = Steps;

const times = Array.from(
  { length: 24 },
  (_, i) => `${i < 10 ? "0" : ""}${i}:00`
);

interface Day {
  name: string;
  selected: boolean;
  openTime: string;
  closeTime: string;
}

interface Business {
  name: string;
  hours: Day[];
}

interface Category {
  _id: string;
  name: string;
  categoryId: string | null;
  category_image: string;
  isHome: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const EditListing = () => {
  const logo = require("../../../../assets/backendassets/logo.png");
  const pincodeData = require("../pinCodeData.json");
  const [form] = Form.useForm();
 
  const imageUrl = process.env.REACT_APP_IMAGE_URL + "/businesse/";
  const { id , stepnumber } = useParams();
  const [current, setCurrent] = useState(0);
  const [businessData, setbusinessData] = useState([]);
  const [galleryFileList, setGalleryFileList] = useState<any>([]);
  const [galleryPreviewImages, setGalleryPreviewImages] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
  const [days, setDays] = useState([
    { name: "Mon", selected: false, openTime: "", closeTime: "" },
    { name: "Tue", selected: false, openTime: "", closeTime: "" },
    { name: "Wed", selected: false, openTime: "", closeTime: "" },
    { name: "Thu", selected: false, openTime: "", closeTime: "" },
    { name: "Fri", selected: false, openTime: "", closeTime: "" },
    { name: "Sat", selected: false, openTime: "", closeTime: "" },
    { name: "Sun", selected: false, openTime: "", closeTime: "" },
  ]);
  const [selectAll, setSelectAll] = useState(false);
  const { authData } = useContext(AuthContext);
  const customerId = authData.user._id;
  const userId = customerId.replace(/"/g, "");
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [pinloading, setPinLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (stepnumber) {
      const stepIndex = parseInt(stepnumber, 10) - 1; // Convert to 0-based index
      setCurrent(stepIndex);
    }
  }, [stepnumber]);

  const handlePincodeChange = async (e: any) => {
    const pincode = e.target.value;
    if (pincode.length === 6) {
      setPinLoading(true);
      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
        const pincodeData = response.data;
        if (pincodeData && pincodeData.length > 0) {
          const firstLocation = pincodeData[0].PostOffice[0];
          form.setFieldsValue({
            city: firstLocation.Region,
            state: firstLocation.State,
          });
        } else {
          form.setFieldsValue({
            city: '',
            state: '',
          });
          message.error("No Pincode Found");
        }
      } catch (error) {
        message.error("No Pincode Found");
      } finally {
        setPinLoading(false);
      }
    }
  };

  const handleCategoryChange = (value: string) => {
    form.setFieldsValue({
      subcategoryId: [],
    })
    const filteredSubCategories = categoryData.filter(
      (category) => category.categoryId === value
    );
    setSubCategories(filteredSubCategories);
  };

  const fetchCategories = async () => {
    try {
      const response = await getAllcategories();
      setCategoryData(response);
    } catch (error) {
      message.error("Network error. Please try again.");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const FetchBusinessDetail = async () => {
    try {
      const response = await EditBusiness(id);
      const galleryImages = response.galleryImage.map((img: any) => ({
        url: `${imageUrl}${img}`,
        name: img,
        status: "done",
        uid: img,
      }));

      setGalleryFileList(galleryImages);
      setGalleryPreviewImages(galleryImages.map((img: any) => img.url));

      const selectedCategoryId = response.categoryId[0]?._id;
      const filteredSubCategories = categoryData.filter(
        (category) => category.categoryId === selectedCategoryId
      );
      setSubCategories(filteredSubCategories);

      // Find subcategory names
      const subCategoryNames = response.subcategoryId.map((subId: any) => {
        const subCategory = filteredSubCategories.find((sub) => sub._id === subId);
        return subCategory ? subCategory.name : subId;
      });
      // Set the form values
      form.setFieldsValue({
        id: response._id,
        businessName: response.businessName,
        categoryId: response.categoryId[0]?._id,
        subcategoryId: response.subcategoryId,
        pincode: response.pincode,
        buildingName: response.buildingName,
        streetName: response.streetName,
        area: response.area,
        contactPersonName: response.contactPersonName,
        mobileNumber: response.mobileNumber.replace("+91-", ""),
        whatsappNumber: response.whatsappNumber.replace("+91-", ""),
        landlineNumber: response.landlineNumber,
        email: response.email,
        galleryImage: response.galleryImage,
        state: response.state,
        city: response.city,
      });



      // Set the business hours
      const businessHours = response.businessHours.map(
        (hour: {
          name: any;
          selected: any;
          openTime: any;
          closeTime: any;
        }) => ({
          name: hour.name,
          selected: hour.selected,
          openTime: hour.openTime,
          closeTime: hour.closeTime,
        })
      );

      setDays(businessHours);
      form.setFieldsValue({ hours: businessHours });
    } catch (error) {
      message.error("Network error. Please try again.");
    }
  };


  useEffect(() => {
    if (categoryData.length > 0) {
      FetchBusinessDetail();
    }
  }, [categoryData]);

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        setbusinessData({ ...businessData, ...values });

        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        // Check if errorInfo contains validation errors
        if (
          errorInfo &&
          errorInfo.errorFields &&
          errorInfo.errorFields.length > 0
        ) {
          message.error("Please fill in all required fields");
          console.error("Validation error:", errorInfo);
        } else {
          console.error("Unexpected error:", errorInfo);
          message.error("An unexpected error occurred. Please try again.");
        }
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleDayChange = (
    index: number,
    field: string,
    value: boolean | string
  ) => {
    const newDays = [...days];
    if (field === "selected") {
      newDays[index].selected = value as boolean;
    } else if (field === "openTime" || field === "closeTime") {
      newDays[index][field] = value as string;
      if (
        field === "openTime" &&
        newDays[index].closeTime &&
        newDays[index].closeTime < value
      ) {
        newDays[index].closeTime = value as string;
      }
    }
    setDays(newDays);
    form.setFieldsValue({ hours: newDays });
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newDays = days.map((day) => ({ ...day, selected: newSelectAll }));
    setDays(newDays);
    form.setFieldsValue({ hours: newDays });
  };

  const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const galleryImageProps = {
    name: "galleryImage",
    multiple: true,
    beforeUpload(file: any) {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      const isLt64M = file.size / 1024 / 1024 < 64;
      if (!isLt64M) {
        message.error("Image must be smaller than 64MB!");
      }
      return isImage && isLt64M;
    },
    onChange(info: any) {
      const newGalleryFileList = [...info.fileList];
      setGalleryFileList(newGalleryFileList);

      const newGalleryPreviewImages = newGalleryFileList.map(async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        return file.url || file.preview;
      });

      Promise.all(newGalleryPreviewImages).then((images) => {
        setGalleryPreviewImages(images);
      });
    },
    fileList: galleryFileList,
  };

  const handleRemoveGalleryImage = async (
    index: any,
    uid: any,
    businessId: any
  ) => {
    try {
      if (galleryFileList[index].url) {
        await deleteimage(businessId, index);
      }
  
      const newGalleryFileList = [...galleryFileList];
      newGalleryFileList.splice(index, 1);
      setGalleryFileList(newGalleryFileList);
  
      const newGalleryPreviewImages = [...galleryPreviewImages];
      newGalleryPreviewImages.splice(index, 1);
      setGalleryPreviewImages(newGalleryPreviewImages);
  
      message.success("Image deleted successfully.");
    } catch (error) {
      message.error("Failed to delete image. Please try again.");
    }
  };
  

  const onFinish = (values: any) => {
    setLoading(true);
    const combinedData = { ...values, ...businessData };

    const formData = new FormData();
    Object.keys(combinedData).forEach((key: any) => {
      if (key !== "galleryImage" && key !== "subcategoryId") {
        formData.append(key, combinedData[key]);
      }
    });

    if (combinedData.subcategoryId && combinedData.subcategoryId.length > 0) {
      combinedData.subcategoryId.forEach((subCatId: string) => {
        formData.append("subcategoryId", subCatId);
      });
    }


    if (galleryFileList.length > 0 || undefined) {
      galleryFileList.forEach((file: any, index: any) => {
        if (galleryFileList[index].originFileObj) {
          formData.append(
            "galleryImage",
            galleryFileList[index].originFileObj as RcFile
          );
        }
      });
    }

    const allDays = days.map((day) => ({
      ...day,
      openTime: day.selected ? day.openTime : "",
      closeTime: day.selected ? day.closeTime : "",
    }));
    formData.append("businessHours", JSON.stringify(allDays));
    formData.append("userId", userId);
    setLoading(true); 
    BusinessUpdate(id, formData)
      .then((response: any) => {
        message.success(response.data.message);
         // Pass data to the success page
         const successData = {
          message: response.data.message,
          businessDetails: combinedData,
          businessHours: allDays,
        };
        navigate("/business/success", { state: successData });
      })
      .catch((error: any) => {
        console.error("API Error:", error);
      }).finally(() => {
        setLoading(false);
      })
  };


  const onFinishForm = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errorInfo) => {
        // Check for validation errors
        if (
          errorInfo &&
          errorInfo.errorFields &&
          errorInfo.errorFields.length > 0
        ) {
          message.error("Please fill in all required fields");
          console.error("Validation error:", errorInfo);
        } else {
          // Log the error to the console for debugging purposes
          console.error("Unexpected error:", errorInfo);
          // Optionally show a generic error message to the user
          message.error("An unexpected error occurred. Please try again.");
        }
      });
  };


  const options = [
    { value: 'Mr' },
    { value: 'Ms' }
  ];

  const steps = [
    {
      title: "General Information",
      imgSrc: img1,
      content: (
        <>
          <Row>
            <Col span={24}>
              <div className="font-bold text-lg">General Information</div>
              <hr className="mt-2" />
              <label className="font-bold text-xl mt-4 ml-1 mb-1">
                Business Name
                <span className="text-red-500">*</span>
              </label>
              <Form.Item
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Business Name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Business Name"
                  size="large"
                  className="Allinput"
                />
              </Form.Item>
              <div>
                <label className="font-bold text-xl ml-1 mb-2">
                  Category <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please Choose Category of your Business!",
                    },
                  ]}
                >
                    <Select
            size="large"
       
            allowClear
            showSearch
            placeholder="Select Category"
            aria-label="Category"
          
            removeIcon={<PlusCircleIcon className="text-yellow-500" />}
            onChange={handleCategoryChange}
            filterOption={(input : any, option : any) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
              {categoryData
              .filter(
                (category) =>
                  (category.categoryId === null ||
                    category.categoryId === '' ||
                    category.categoryId === 'null') &&
                  category.isDeleted === false
              )
              .map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <label className="font-bold text-xl ml-1 mb-2">
                  Sub Category <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="subcategoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please Choose sub category of your Business!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    size="large"
                    allowClear
                    placeholder="Select sub category"
                  >
                    {subCategories.map((subCategory) => (
                      <Option key={subCategory._id} value={subCategory._id}>
                        {subCategory.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <label className="font-bold text-xl ml-1 mb-1">
                  PinCode <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="pincode"
                  rules={[
                    { required: true, message: "Please input your Pincode" },
                    {
                      pattern: new RegExp(/^[1-9][0-9]{5}$/),
                      message: "Please enter a valid pincode",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter PinCode"
                    size="large"
                    className="Allinput"
                    onChange={handlePincodeChange}
                  />
                </Form.Item>
              </div>
              <div>
                <label className="font-bold text-xl ml-1 mb-1">
                  Block No / Building Name{" "}
                  <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="buildingName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Building Name / Block no",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Building Name"
                    size="large"
                    className="Allinput"
                  />
                </Form.Item>
              </div>
              <div>
                <label className="font-bold text-xl ml-1 mb-1">
                  Street / Colony Name <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="streetName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Street Name / Colony Name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Colony / Street Name"
                    size="large"
                    className="Allinput"
                  />
                </Form.Item>
              </div>
              <div>
                <label className="font-bold text-xl ml-1 ">
                  City <span className="text-red-500">*</span>{" "}
                </label>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please input your City",
                    },
                  ]}
                >
                  <Input placeholder="City" size="large" className="Allinput" />
                </Form.Item>
              </div>
              <div>
                <label className="font-bold text-xl ml-1 ">
                  State <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please input your State",
                    },
                  ]}
                >
                  <Input
                    placeholder="State"
                    size="large"
                    className="Allinput"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Contact Information",
      imgSrc: img2,
      content: (
        <>
          <div>
            <Row>
              <Col span={24}>
                <div className="font-bold text-lg">Add Contact Details</div>
                <hr className="mt-2 mb-2" />
              </Col>
              <Col span={24} className="pr-3">
                <label className="font-bold text-xl ml-1 mb-1 mt-4">
                  Contact Person <span className="text-red-500">*</span>
                </label>
                <Row gutter={16} align="middle" className="form-row">
      <Col xs={7} sm={6} md={6} lg={3} xl={3} xxl={3}>
        <Form.Item className="select-title">
        <AutoComplete
                    options={options}
                    bordered={false}
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(inputValue : any, option : any) => option?.value.toLowerCase().includes(inputValue.toLowerCase())}
                    placeholder="Select a state"
                  >
                    <Input
                    
                      style={{ borderRadius: "4px", height: "50px" }}
                    />
                  </AutoComplete>
        </Form.Item>
      </Col>
      <Col xs={17} sm={18} md={18} lg={21} xl={21} xxl={21}>
        <Form.Item
          name="contactPersonName"
          rules={[
            {
              required: true,
              message: "Please input Contact Person Name",
            },
            {
              pattern: new RegExp(/^[a-zA-Z\s]*$/),
              message: "Please enter a valid name",
            },
          ]}
        >
          <Input
            placeholder="Enter Contact Person Name"
            size="large"
            className="Allinput"
          />
        </Form.Item>
      </Col>
    </Row>
              </Col>

              <Col span={24} className="pr-3">
                <Row gutter={16} className="align-items-baseline">
                  <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <Input value="+91" className="Allinput" readOnly />
                  </Col>
                  <Col xs={16} sm={18} md={16} lg={21} xl={21} xxl={21}>
                    <Form.Item
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input Mobile Number",
                        },
                        {
                          pattern: new RegExp(/^\d{10}$/),
                          message:
                            "Please enter a valid 10-digit Mobile Number",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Mobile Number"
                        className="Allinput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} className="align-items-baseline">
                  <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <Input defaultValue="+91" className="Allinput" readOnly />
                  </Col>
                  <Col xs={16} sm={18} md={16} lg={21} xl={21} xxl={21}>
                    <Form.Item
                      name="whatsappNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input Mobile Number",
                        },
                        {
                          pattern: new RegExp(/^\d{10}$/),
                          message:
                            "Please enter a valid 10-digit Mobile Number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Whatsapp Number"
                        className="Allinput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} className="align-items-baseline">
                  <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <Input defaultValue="+91" className="Allinput" readOnly />
                  </Col>
                  <Col xs={16} sm={18} md={16} lg={21} xl={21} xxl={21}>
                    <Form.Item name="landlineNumber">
                      <Input
                        placeholder="Landline Number"
                        className="Allinput"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24} className="pr-3">
                <label className="font-bold text-xl  ml-1 mb-1">
                  Email <span className="text-red-500">*</span>
                </label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                    {
                      required: true,
                      message: "Please input your email address",
                    },
                  ]}
                >
                  <Input placeholder="Email" className="Allinput" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </>
      ),
    },
    {
      title: "Business Hours",
      imgSrc: img3,
      content: (
        <>
          <>
            <Row>
              <Col span={24}>
                <div className="font-bold text-lg">Add Business Timings</div>
                <hr className="mt-2 mb-2" />
              </Col>
              <Col span={24}>
                <div className="mt-4">
                  <div className="flex space-x-2">
                    {days.map((day, index) => (
                      <Button
                        key={day.name}
                        className={
                          day.selected ? "selected-day" : "timiingSlotcheckbox"
                        }
                        onClick={() =>
                          handleDayChange(index, "selected", !day.selected)
                        }
                      >
                        {day.name}
                      </Button>
                    ))}
                  </div>

                  <div className="mt-2">
                    <Checkbox checked={selectAll} onChange={handleSelectAll}>
                      Select All Days
                    </Checkbox>
                  </div>

                  <div className="mt-4">
                    <Row gutter={16}>
                      <Col xs={24} md={12}>
                        <label className="block">Open at</label>
                        <Select
                          size="large"
                          className="mt-1 block w-full"
                          value={
                            days.find((day) => day.selected)?.openTime || ""
                          }
                          onChange={(value) => {
                            setDays(
                              days.map((day) =>
                                day.selected
                                  ? {
                                    ...day,
                                    openTime: value,
                                    closeTime:
                                      day.closeTime < value
                                        ? value
                                        : day.closeTime,
                                  }
                                  : day
                              )
                            );
                          }}
                        >
                          <Option value="">Select</Option>
                          {times.map((time) => (
                            <Option key={time} value={time}>
                              {time}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={24} md={12}>
                        <label className="block">Close at</label>
                        <Select
                          size="large"
                          className="mt-1 block w-full"
                          value={
                            days.find((day) => day.selected)?.closeTime || ""
                          }
                          onChange={(value) => {
                            const selectedDayIndex = days.findIndex(
                              (day) => day.selected
                            );
                            if (selectedDayIndex !== -1) {
                              const selectedDay = days[selectedDayIndex];
                              const newCloseTime = value as string;
                              // Ensure close time cannot be earlier than open time
                              if (
                                selectedDay.openTime &&
                                newCloseTime < selectedDay.openTime
                              ) {
                                // If close time is earlier, set it to open time
                                setDays(
                                  days.map((day, index) =>
                                    index === selectedDayIndex
                                      ? {
                                        ...day,
                                        closeTime: selectedDay.openTime,
                                      }
                                      : day
                                  )
                                );
                              } else {
                                // Otherwise, update close time normally
                                setDays(
                                  days.map((day) =>
                                    day.selected
                                      ? { ...day, closeTime: newCloseTime }
                                      : day
                                  )
                                );
                              }
                            }
                          }}
                        >
                          <Option value="">Select</Option>
                          {times.map((time) => (
                            <Option key={time} value={time}>
                              {time}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        </>
      ),
    },
    {
      title: "Gallery",
      imgSrc: img4,
      content: (
        <>
          <Row>
            <Col span={24}>
              <div className="font-bold text-lg">Gallery</div>
              <hr className="mt-2" />
              <label className="font-bold text-xl mt-4 ml-1 mb-1">
                Gallery Images
              </label>
              <Form.Item name="galleryImage">
                <Dragger {...galleryImageProps}>
                  <p className="ant-upload-drag-icon d-flex justify-center">
                    <BiSolidInbox />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other banned files.
                  </p>
                </Dragger>
              </Form.Item>
              <div className="gallery-preview">
                <div className="grid grid-cols-2 gap-4">
                  {galleryFileList.map((file: any, index: any) => (
                    <div key={index} className="relative">
                      <img
                        src={file.url || file.preview}
                        alt={`Carousel ${index}`}
                      />
                      <button
                        className="absolute top-0 right-0 bg-yellow-700 text-black p-1"
                        onClick={() =>
                          handleRemoveGalleryImage(index, file.uid, id)
                        }
                      >
                        <CloseOutlined />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={12}>
          <img src={logo} className="w-auto h-16" />
        </Col>
        <Col span={12} className="d-flex text-lg justify-end pr-10 pt-4">
          Do you have account ?
          <Link to="/login" className="text-yellow font-semibold">
            Login
          </Link>
        </Col>
      </Row>
      <Form form={form} layout="vertical" validateTrigger={[]}>
        <Row className="form-container px-4 py-5 w-3/4 mx-auto rounded-xl ">
          {/* <Progress  percent={profileStrength} /> */}
          <Col span={24}>
            {/* <Steps current={current}>
            {steps.map((step, index) => (
              <Step key={index} title={step.title} />
            ))}
          </Steps> */}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="hidden lg:block"
          >
            <img src={steps[current].imgSrc} className="w-full " />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="steps-content mt-4">
              {steps[current].content}
              <div className="steps-action flex justify-end mt-4">
                {current > 0 && (
                  <button
                    className="rounded-md text-black p-3 mr-1 primary w-full bg-yellow-700"
                    onClick={() => prev()}
                  >
                    Previous
                  </button>
                )}
                {current < steps.length - 1 && (
                  <button
                    className="rounded-md text-black p-3 primary w-full bg-yellow-700"
                    onClick={() => next()}
                  >
                    Save & Continue
                  </button>
                )}
                 {current === steps.length - 1 && (
      <button
        className="rounded-md text-black p-3 primary w-full bg-yellow-700"
        type="submit"
        onClick={onFinishForm}
        disabled={loading} // Disable the button while loading
      >
        {loading ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Spin size="small" /> {/* Spinner */}
            <span style={{ marginLeft: '8px' }}>Submitting...</span> {/* Text */}
          </div>
        ) : (
          'Submit'
        )}
      </button>
    )}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      {/* </div> */}
    </>
  );
};

export default EditListing;