import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaTwitter } from "react-icons/fa";
import Banner from "../Pricing/Banner";
import { ContactUsapi } from "../../../utils/API";
import { Link } from "react-router-dom";
import contactus from "../../../assets/frontendassets/About us/contactus.jpg";

function ContactUs() {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    try {
      const response = await ContactUsapi(values);
      if (response && response.data && response.data.message) {
        message.success(response.data.message);
        form.resetFields();
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <>
     <div
  className="relative h-48 sm:h-64 md:h-80 lg:h-96 bg-cover bg-center"
  style={{ backgroundImage: `url(${contactus})`, backgroundSize: 'cover' }}
>
  <div className="absolute inset-0 bg-black bg-opacity-25 flex flex-col items-start justify-center px-4">
    <h1 className="text-white text-xl sm:text-2xl md:text-4xl lg:text-5xl font-bold ml-4 sm:ml-8 lg:ml-12 text-left" style={{fontFamily: '"Manrope", sans-serif'}}>
      Explore The Worlds
    </h1>
    <p className="text-white text-xs sm:text-sm md:text-base lg:text-lg mt-2 ml-4 sm:ml-8 lg:ml-12 text-left"style={{fontFamily: '"Manrope", sans-serif'}}>
      People Don't Take, Trips Take People
    </p>
  </div>
</div>


      <div className="flex items-center justify-center pt-10 px-4">
        <div className="w-full max-w-screen-lg bg-white rounded-lg shadow-lg p-6">
          <Row gutter={16}>
          <Col xs={24} sm={24} md={12} className="mb-6"> {/* Added margin-bottom */}  
  <div className="text-black text-2xl md:text-3xl mt-2 mb-8 px-4 py-2 rounded">
  <p style={{fontFamily: '"Manrope", sans-serif'}}> Contact-Us </p> 
  </div>
  <Form layout="vertical" form={form} onFinish={handleSubmit}>
  <Row gutter={16}>
    <Col xs={24} sm={12}>
      <Form.Item name="name" rules={[{ required: true, message: "Please enter your name" }]}>
        <Input
          className="h-12 text-base bg-gray-200 border-gray-200 rounded-full sm:rounded-none focus:outline-none focus:ring-0 focus:border-yellow-500 focus:border-2"
          placeholder="Your Name"
        />
      </Form.Item>
    </Col>
    <Col xs={24} sm={12}>
      <Form.Item name="phone" rules={[{ required: true, message: "Please enter your phone number" }]}>
        <Input
          className="h-12 text-base bg-gray-200 border-gray-200 rounded-full sm:rounded-none focus:outline-none focus:ring-0 focus:border-yellow-500 focus:border-2"
          placeholder="Phone Number"
        />
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col xs={24} sm={12}>
      <Form.Item name="email" rules={[{ required: true, message: "Please enter your email address" }]}>
        <Input
          className="h-12 text-base bg-gray-200 border-gray-200 rounded-full sm:rounded-none focus:outline-none focus:ring-0 focus:border-yellow-500 focus:border-2"
          type="email"
          placeholder="Email Address"
        />
      </Form.Item>
    </Col>
    <Col xs={24} sm={12}>
      <Form.Item name="subject" rules={[{ required: true, message: "Please enter a subject" }]}>
        <Input
          className="h-12 text-base bg-gray-200 border-gray-200 rounded-full sm:rounded-none focus:outline-none focus:ring-0 focus:border-yellow-500 focus:border-2"
          placeholder="Subject"
        />
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col span={24}>
      <Form.Item name="message" rules={[{ required: true, message: "Please enter a message" }]}>
        <Input.TextArea
          className="h-32 text-base bg-gray-200 border-gray-200 rounded-full sm:rounded-none focus:outline-none focus:ring-0 focus:border-yellow-500 focus:border-2"
          placeholder="Write a Message"
          rows={6}
        />
      </Form.Item>
    </Col>
  </Row>
  <Form.Item>
    <Button
      className="bg-yellow-700 h-12 text-black font-bold w-full"
      htmlType="submit"
      block
    >
      Send a Message
    </Button>
  </Form.Item>
</Form>



</Col>

<Col xs={24} sm={24} md={12} className="mb-6"> {/* Added margin-bottom */}
  <div className="p-4 bg-white rounded-lg">
    <h1 className="text-2xl font-bold mb-6" style={{fontFamily: '"Manrope", sans-serif'}}>Our Offices</h1>
    <div className="flex items-center mb-5">
      <a href="tel:+3155222256">
        <PhoneOutlined className="text-black bg-yellow-700 rounded-full p-3 text-lg" />
      </a>
      <div className="ml-3">
        <strong className="block text-lg font-semibold text-gray-600" style={{fontFamily: '"Manrope", sans-serif'}}>
          Have any questions?
        </strong>
        <p className="text-lg text-black font-medium">
          +91 9818755350
        </p>
      </div>
    </div>
    <div className="flex items-center mb-5">
      <a href="mailto:contact@example.com">
        <MailOutlined className="text-black bg-yellow-700 rounded-full p-3 text-lg" />
      </a>
      <div className="ml-3">
        <strong className="block text-lg font-semibold text-gray-600" style={{fontFamily: '"Manrope", sans-serif'}}>
          Send Email
        </strong>
        <p className="text-lg text-black font-medium">
          contact@net4surge.com
        </p>
      </div>
    </div>
    <div className="flex items-center mb-5">
      <a href="https://www.google.com/maps/place/80+Broklyn+Road,+New+York">
        <EnvironmentOutlined className="text-black bg-yellow-700 rounded-full p-3 text-lg" />
      </a>
      <div className="ml-3">
        <strong className="block text-lg font-semibold text-gray-600"style={{fontFamily: '"Manrope", sans-serif'}} >
          Visit any time
        </strong>
        <p className="text-lg text-black font-medium">
          D-9, Vyapar Marg, Block D, Noida Sector 3, Noida, Uttar Pradesh 201301
        </p>
      </div>
    </div>
    <hr className="my-6" />
    <div className="flex justify-around mt-6">
      <Link
        to="https://www.twitter.com/Net4Surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-yellow-500 hover:text-black"
      >
        <FaTwitter className="text-lg" />
      </Link>

      <Link
        to="https://www.facebook.com/net4surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-yellow-500 hover:text-black"
      >
        <FaFacebook className="text-lg" />
      </Link>

      <Link
        to="https://www.instagram.com/net4surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-yellow-500 hover:text-black"
      >
        <FaInstagram className="text-lg" />
      </Link>

      <Link
        to="https://www.linkedin.com/company/net4surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-yellow-500 hover:text-black"
      >
        <FaLinkedin className="text-lg" />
      </Link>
    </div>
  </div>
</Col>

          </Row>
        </div>
      </div>

      <Banner />
    </>
  );
}

export default ContactUs;
