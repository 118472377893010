import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { getFeature, getAllPackages, createRazorpayOrder, verifyBusinessPayment, Userplan } from "../../../utils/API";
import { message } from "antd";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../../Auth/AuthContext";
import Spinner from 'antd/lib/spin';

const PricingPlan: React.FC = () => {

  const [featureData, setFeatureData] = useState([]);
  const [packageData, setPackageData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [months, setMonths] = useState(1); // New state for months
  const { authData } = useContext(AuthContext);
  const customerId = authData.user._id;
  const userId = customerId.replace(/"/g, '');
  const navigate = useNavigate();

  useEffect(() => {
    fetchFeatureData();
    fetchPackageData();
  }, []);

  // const handleClick = () => {
  //   setIsLoading(true);
  // };

  const fetchFeatureData = async () => {
    try {
      const data = await getFeature();
      setFeatureData(data);

    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const fetchPackageData = async () => {
    try {
      const data = await getAllPackages();

      setPackageData(data);
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const handlePurchase = (pkg: any) => {

    setSelectedPackage(pkg);
    setIsOpen(true);
  };

  const closeModal = async () => {
    setIsLoading(true);
    const paymentMethod = "Razorpay";

    const orderData = {
      amount: selectedPackage.price * months * 30 * 100,
      currency: "INR",
      receipt: `receipt_trusted_${Date.now()}`,
      paymentMethod,
    };

    try {
      const order = await createRazorpayOrder(orderData);
      const { id: order_id, currency, amount } = order;

      const options = {
        key: process.env.RAZOR_PAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "Net4Surge",
        description: "Test Transaction for Trusted Business",
        order_id: order_id,
        handler: async function (response: any) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const verifyResult = await verifyBusinessPayment(paymentData);
            if (verifyResult == "OK") {
              setIsLoading(false);
              message.success("Payment successful.");
              let transactionId = response.razorpay_payment_id;
              const userPlanData = {
                userId: userId,
                packageID: selectedPackage,
                isActive: 1,
                totalAmount: selectedPackage.price * months * 30,
                planAmount: selectedPackage.price,
                month: months,
                transactionId,
              };

              try {
                const response = await Userplan(userPlanData);
                if (response.status === 200) {
                  navigate("/backend/mywebsite");
                } else {
                  message.error("Unexpected response status: " + response.status);
                }
              } catch (error) {
                message.error("An error occurred while processing your payment. Please try again.");
              }
            } else {
              message.error("Failed to update business verification status.");
            }
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            message.error(
              "Error verifying payment for Trusted Business. Please try again."
            );
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "9999999999",
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
    } catch (error: any) {
      message.error(
        "Error creating Razorpay order for Trusted Business:",
        error
      );
    }
  };


  const handleMonthsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    setMonths(value > 0 ? value : 1); // Ensure months is at least 1
  };

  const handleOverlayClick = (e: any) => {
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div className="container mx-auto my-1">
        <div className="flex flex-wrap justify-center">
          <div className="w-full sm:w-5/12 px-1">
            <div className="bg-gray-200 text-lg p-2 text-center font-bold">
              NET4SURGE PRICING PLANS
            </div>
          </div>
          <div className="w-full sm:w-6/12 px-1">
            <div className="bg-yellow-200 text-lg p-2 font-bold flex items-center">
              <span className="whitespace-nowrap">
                To upgrade your plan please contact on +91 98187 55350
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto max-h-screen p-12">
        <div className="px-12">
          <table className="w-full border-collapse text-center font-sans">
            <thead>
              <tr>
                <td className="p-2 border border-gray-300 bg-white"></td>
                {packageData.map((pkg: any) => (
                  <th
                    key={pkg.packageName}
                    className="p-2 border border-gray-300 bg-gray-100"
                  >
                    <div className="flex flex-col items-center p-2 rounded">
                      <div>{pkg.packageName}</div>
                      <div className="text-xl text-black">{pkg.price} Rs.</div>
                      <div>/{pkg.duraction}</div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                  Search Visibility
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
                <td className="p-2  bg-yellow-100 text-lg font-medium truncate border-r">
                </td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Category Banner
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">1✔️ </td>
                <td className="p-2 border border-gray-300 bg-yellow-100">2✔️</td>
                <td className="p-2  bg-yellow-100 text-lg font-medium truncate border-r">
                </td>

              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                QR code Genration for Promotions
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
                <td className="p-2  bg-yellow-100 text-lg font-medium truncate border-r">
                  Professional
                </td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Geotargeted free ad setup
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">1✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">2✔️</td>
                <td className="p-2 bg-yellow-100 text-lg font-medium truncate">
                  Plan + Business <br/>
                  Website with SEO
                </td>
              </tr>
              {/* <tr>
                <td className="p-2 border border-gray-300 bg-white">
                  Online Cataloge
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">❌</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
                <td className="p-2 bg-yellow-100 text-lg font-medium truncate">
                  Website With SEO
                </td>
              </tr> */}
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                influencer Marketing Connect
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">❌</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
                <td className="p-2 bg-yellow-100 text-lg font-medium truncate">
                </td>


              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                  Social Media Account Creation&Optimization
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
                <td className="p-2  bg-yellow-100 text-lg font-medium truncate border-r">
                </td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Social Media Posts Creation
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">1✔️</td>
                <td className="p-2 border border-gray-300 bg-red-100">2✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">4✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                Social Media Management
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">❌</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Competitor Analysis Report
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Premium Customer Support
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">❌</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                  Dedicated Account Manager
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Business Card, Brouchure
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                  Business  Consulting Report
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">❌</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>
              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                Social Media Likes&Follwers
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌ 1K like </td>
                <td className="p-2 border border-gray-300 bg-red-100">✔️1k likes & 1k followers</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>

              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-gray-100">
                Whatsapp Business Automation Tool
                </td>
                <td className="p-2 border border-gray-300 bg-teal-100">❌</td>
                <td className="p-2 border border-gray-300 bg-red-100">❌</td>
                <td className="p-2 border border-gray-300 bg-yellow-100">✔️</td>

              </tr>
              <tr>
                <td className="p-2 border border-gray-300 bg-white">
                  {""}
                </td>
                {packageData.map((pkg: any) => (
                  <td
                    key={pkg._id}
                    className="p-2 border border-gray-300 bg-white"
                  >
                    <button
                      className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600"
                      onClick={() => handlePurchase(pkg)}
                    >
                      Buy Now
                    </button>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* {selectedPackage && (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full w-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <p className="text-yellow-500 text-2xl font-bold">
                To upgrade your plan please contact on +91 98187 55350
              </p>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
)} */}

{isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={handleOverlayClick}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-yellow-500 text-2xl font-bold">
              To upgrade your plan please contact on +91 98187 55350
            </p>
          </div>
        </div>
      )}

    </>
  );
};

export default PricingPlan;