import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { IoIosArrowDown, IoIosMail } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import {
  FaFacebook,
  FaInstagram,
  FaPhoneSquareAlt,
  FaTwitter,
} from "react-icons/fa";
import { Row, Col } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dummyuser from "../../assets/frontendassets/dummyuser.png";
import { AuthContext } from "../Auth/AuthContext";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  { name: "Home", href: "/", current: true, dropdownItems: [] },
  { name: "Explore Listings", href: "/listing", current: true, dropdownItems: [] },
  { name: "Contact", href: "/contact-us", current: false, dropdownItems: [] },
];

const HeaderUser = () => {
  const logo = require("../../assets/backendassets/logo.png");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const { authData, setAuthData } = useContext(AuthContext);
  const token = authData.token;
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname;
  const handleLogout = () => {
    localStorage.clear();
    setAuthData({
      token: null, user: null,
      roleId: undefined
    });
    navigate("/login");
  };


  const profileImage = () => {
    return authData?.user?.image
      ? `${process.env.REACT_APP_IMAGE_URL}/users/${authData?.user?.image}`
      : "";
  };

  useEffect(() => {
    setProfileImageUrl(profileImage());
  }, [authData]);

  return (
    <>
      <div 
        className="md:ps-0 md:px-0 xl:px-24 xl:ps-24 pt-2 pb-2 sticky top-0 left-0 right-0 z-10 "
        style={{ backgroundColor: "#11161f" }}
      >
        <Row className="container" justify={"space-between"} align={"middle"}>
          <div className="hidden xl:block xxl:block">
            <Row justify={"center"} align={"middle"}>
              <IoIosMail className="bg-#11161f text-yellow-400" size={22} />
              <span
                className="hover:text-white font-semibold mr-7"
                style={{
                  color: "#838383",
                  cursor: "pointer",
                  fontSize: "13px",
                  padding: "0 0 0 6px",
                }}
              >
                contact@net4surge.com
              </span>
              <FaPhoneSquareAlt
                className="bg-#11161f text-yellow-400"
                size={18}
              />
              <span
                className="hover:text-white ml-2 font-semibold"
                style={{
                  color: "#838383",
                  cursor: "pointer",
                  fontSize: "13px",
                  padding: "0 0 0 6px",
                }}
              >
                +91 9818755350
              </span>
            </Row>
          </div>

          <div
            className="font-normal text-xs sm:text-base"
            style={{ color: "#838383" }}
          >
            <Row justify={"space-between"} align={"middle"} className="">
              <Col className="d-flex">
                <Link to="/about-us">
                  <ul className="ms-1 me-1">About</ul>
                </Link>
                <span className="font-semibold ms-1 me-1">/</span>
                <Link to="/contact-us">
                  <ul className="ms-1 me-1">Contact</ul>
                </Link>
              </Col>
              <Col className="d-flex">
                <Link to="https://www.facebook.com/net4surge/" target="_blank" rel="noopener noreferrer">
                  <FaFacebook className="text-silver ms-1 me-1 h-2.5 w-2.5 sm:h-4 sm:w-4" />
                </Link>
                <Link to="https://www.twitter.com/Net4Surge/" target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="text-silver ms-1 me-1 h-2.5 w-2.5 sm:h-4 sm:w-4" />
                </Link>
                <Link to="https://www.instagram.com/net4surge/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="text-silver ms-1 me-1 h-2.5 w-2.5 sm:h-4 sm:w-4" />
                </Link>
                <Link to="https://www.linkedin.com/company/net4surge/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="text-silver ms-1 me-1 h-2.5 w-2.5 sm:h-4 sm:w-4" />
                </Link>
              </Col>
            </Row>
          </div>

        </Row>
      </div>
      <Disclosure
        as="nav"
        className="top-[1.95rem] md:ps-0 md:px-0 xl:px-10 xl:ps-2 xl:pt-4 xl:pb-4 fixed z-50  left-0 right-0 bg-white shadow-md"
      >
        {({ open }) => (
          <>
            <div className="max-w-100 px-2 ">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex flex-1 items-center justify-start  sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <img
                        className="h-12 w-auto sm:h-16 sm:max-w-xs md:h-14 md:max-w-md lg:h-16 lg:max-w-lg xl:h-20 xl:max-w-xl"
                        src={logo}
                        alt="Your Company"
                      />
                    </Link>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto  sm:pr-0">
                  <div className="hidden sm:ml-6 xl:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <div key={item.name} className="relative">
                          {item.dropdownItems.length > 0 ? (
                            <Menu as="div" className="relative">
                              <Menu.Button
                                className="relative flex items-center rounded-md text-lg font-bold"
                                style={{ color: "#838383" }}
                              >
                                <span style={{ paddingTop: "2px"}}>{item.name}</span>
                                <IoIosArrowDown
                                  className="ml-1 h-2 w-3"
                                  aria-hidden="true"
                                  style={{ color: "#838383", marginTop: "5px" }}
                                />
                              </Menu.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"

                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className=" absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {item.dropdownItems.map((dropdownItem) => (
                                    <Menu.Item key={dropdownItem}>
                                      {({ active }) => (
                                        <Link
                                          to={item.href}
                                          className={classNames(
                                            active ? " bg-gray-100 text-yellow-700 w-full" : "",
                                            "block w-full px-4 py-2 text-base text-gray-700"
                                          )}
                                          
                                        >
                                          {dropdownItem}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          ) : (
                            <Link
                              to={item.href}
                              className={classNames(
                                " px-3 py-2 text-lg font-bold",
                                currentPath === item.href
                                  ? "border-b-2 border-yellow-500  !hover:text-black-500"
                                  : "text-gray-500 hover:text-black"
                              )}
                              style={currentPath === item.href ? { color: "#6B7280" } : {}}

                            
                              aria-current={currentPath === item.href ? "page" : undefined}
                            >
                              {item.name}
                            </Link>

                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <Menu as="div" className="relative ml-3 mr-3 ">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={profileImageUrl || dummyuser}
                          alt="User  Profile"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {
                          token ? (
                            <>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/backend/profile"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Your Profile
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/my-listing"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    My Businesses
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/login"
                                    onClick={handleLogout}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Sign out
                                  </Link>
                                )}
                              </Menu.Item>

                            </>
                          ) : (
                            <>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/login"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Login
                                  </Link>
                                )}
                              </Menu.Item>

                            </>
                          )
                        }
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <div className="hidden xl:block xxl:block ">
                    <Link to="/add-listing">
                      <button
                        type="button"
                        className=" bg-yellow-700 d-flex justify-center btn btn-lg text-black  align-items-center "
                      >
                        <PlusCircleIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                          color="black"
                        />
                        <span className="ext-white ml-3">Add Listing</span>
                      </button>
                    </Link>
                  </div>

                  <Disclosure.Button className="relative inline-flex items-center xl:hidden justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:block md:block ">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <div key={item.name} className="w-full">
                    {item.dropdownItems.length > 0 ? (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              as="div"
                              className={classNames(
                                item.current ? "bg-gray-100 w-full" : "w-full",
                                " px-4 py-2 text-sm text-gray-700  flex justify-start w-full"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              <Link className="w-full" to={item.href}>
                                {item.name}
                              </Link>
                              <IoIosArrowDown
                                className="ml-1 h-2 w-3 mt-2"
                                aria-hidden="true"
                                style={{ color: "#838383" }}
                              />
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Disclosure.Panel>
                                <div className="pl-2 space-y-1">
                                  {item.dropdownItems.map((dropdownItem) => (
                                    <Link
                                      key={dropdownItem}
                                      to={dropdownItem}
                                      className={classNames(
                                        "block px-4 py-2 text-sm text-gray-700 w-full",
                                        "hover:bg-gray-100 hover:text-yellow-700 w-full"
                                      )}
                                    >
                                      {dropdownItem}
                                    </Link>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <Disclosure.Button
                        className={classNames(
                          item.current ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <Link to={item.href || "/"}>{item.name}</Link>
                      </Disclosure.Button>
                    )}
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default HeaderUser;
