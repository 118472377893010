import React, { useEffect, useState } from "react";
import { Col, Row, Badge } from "antd";
import { FaStar, FaPhoneSquare } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { getAllBusiness } from "../../../utils/API";
import { useNavigate } from "react-router-dom";
import verifiedlogo from "../../../assets/frontendassets/N4S logo pn.png";
import { Dropdown, Menu } from 'antd';
import { FaChevronDown } from 'react-icons/fa';
const LatestListing = () => {
  const [businessData, setBusinessData] = useState<any[]>([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/businesse/';
  const navigate = useNavigate();
  const [verifiedFilter, setVerifiedFilter] = useState(false);
  const [trustedFilter, setTrustedFilter] = useState(false);

  useEffect(() => {
    handleGetBusiness();
  }, []);

  const handleGetBusiness = async () => {
    try {
      const res = await getAllBusiness("", "", "");
      const filteredData = res.data.filter((item: any) => item.isHome === 1).reverse().slice(0, 6);
      setBusinessData(filteredData);
    } catch (error) {
      console.error("Failed to fetch business data", error);
    }
  };

  const filterBusinessData = (data: any) => {
    return data.filter((business: any) => {
      if (verifiedFilter && business.isVerified !== 2) return false;
      if (trustedFilter && business.isTrusted !== 2) return false;
      return true;
    });
  };

  const handleNavigate = (slug: any) => {
    navigate(`/business/${slug}`);
  };

  const isBusinessOpen = (businessHours: any) => {
    const dayIndex = new Date().getDay(); // Get current day index (0 = Sunday, 1 = Monday, etc.)
    const daysMap = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const currentDayName = daysMap[dayIndex];
    const currentDay = businessHours.find((hour: any) => hour.name === currentDayName);
  
    if (currentDay && currentDay.selected) {
      const now = new Date();
      const currentTime = now.getHours() * 100 + now.getMinutes(); // Convert current time to 24-hour format
  
      const openTime = parseInt(currentDay.openTime.replace(":", ""), 10);
      const closeTime = parseInt(currentDay.closeTime.replace(":", ""), 10);
  
      return currentTime >= openTime && currentTime <= closeTime;
    }
  
    return false; // Default to closed if no matching day is found
  };
  

  return (
    <>
      <Row className="md:pt-8 md:ps-8 md:px-8 xl:pt-24 xl:ps-24 xl:px-24 p-2 sm:pt-8 sm:ps-8 sm:px-8" justify={"center"}>
        <Col span={24}>
          <p
            style={{ fontSize: "14px", letterSpacing: "1px" }}
            className="text-center font-bold pb-3 text-yellow"
          >
            HANDPICKED PLACES
          </p>
          <p
            style={{ letterSpacing: "-1px", fontFamily: '"Manrope", sans-serif' }} 
            className="text-center text-5xl font-bold text-black" >
            See latest listings
          </p>
        </Col>
      </Row>

      <div className="md:px-12 md:py-12 xl:px-48 xl:pt-12 bg-white">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 px-4">
    {businessData.map((item: any, index: any) => (
      <div key={index} className="rounded overflow-hidden shadow-lg">
        <a href="#">
          <div className="relative">
            <img className="w-full h-60" src={imageUrl + item.galleryImage[0]} alt="Sunset in the mountains" onClick={() => handleNavigate(item.slug)} />
            <div className="absolute bottom-4 right-4 w-12 h-12"></div>
            <div className={`absolute top-1 left-1 w-12 h-12 text-sm text-white rounded-md flex items-center justify-center mt-3 ml-3 ${isBusinessOpen(item.businessHours) ? 'bg-green-600' : 'bg-red-600'}`}>
              <small className="font-bold">{isBusinessOpen(item.businessHours) ? 'Open' : 'Closed'}</small>
            </div>
          </div>
        </a>
        <div className="pt-4 ml-4">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-0.5 m-0">
              {[...Array(3)].map((_, i) => (
                <FaStar key={i} className="mr-1" size={15} color="yellow" />
              ))}
              {[...Array(2)].map((_, i) => (
                <FaStar key={i} className="mr-1" size={15} color="silver" />
              ))}
              <div className="flex items-center space-x-3 ms-2">
                {item.isVerified === 2 && (
                  <div className="flex items-center space-x-1">
                    <img src={verifiedlogo} alt="logo" className="w-4 h-6" />
                    <p className="text-xs font-medium text-white bg-blue-400 px-2 py-1">Verified</p>
                  </div>
                )}
                {item.isTrusted === 2 && (
                  <div className="flex items-center space-x-1">
                    <img src={verifiedlogo} alt="logo" className="w-4 h-6" />
                    <p className="text-xs font-medium text-white bg-yellow-700 px-2 py-1">Trusted</p>
                  </div>
                )}
              </div>
            </div>
            <p className="text-xs m-0">(3 Reviews)</p>
          </div>

          <Col span={24} className="font-bold mt-1" style={{ fontSize: "22px" }}>
            {item.businessName}
          </Col>
          <Col span={24} className="font-lighter text-gray-500 text-sm mt-1">
            {item.description}
          </Col>
          <div className="flex items-center pt-3" >
            {item.categoryId.length > 0 && (
              <Badge className="text-xs rounded-sm px-3 py-1 bg-blue-600 text-white hover:bg-blue-600 transition-all duration-200" style={{ fontFamily: '"Manrope", sans-serif' }}>
                {item.categoryId[0].name} {/* Display the name of the first category */}
              </Badge>
            )}  
          </div>
          {/* Dropdown for subcategories */}
          <div className="flex flex-wrap gap-2 pt-3">
            <Dropdown
              overlay={
                <Menu>
                  {item.subcategoryId.map((subcat: any, idx: number) => (
                    <Menu.Item key={idx}>
                      {subcat.name}  {/* Display the name of subcategory */}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className="text-xs cursor-pointer bg-blue-600 text-white px-3 py-1 rounded-sm flex items-center hover:bg-blue-600 transition-all duration-200" style={{ fontFamily: '"Manrope", sans-serif' }}>
                Sub-categories <FaChevronDown className="ml-2" size={12} />
              </div>
            </Dropdown>
          </div>

          <div className="flex items-center mt-3">
            <IoLocationSharp className="mr-1 text-yellow" size={20} />
            <p className="font-semibold text-gray-500 hover:text-yellow">
              {item.buildingName}, {item.streetName}, {item.state} {item.pincode}
            </p>
          </div>
          <Row className="d-flex mt-3 mb-4" align={"middle"}>
            <FaPhoneSquare className="mr-2 text-yellow" size={18} />
            <p className="font-semibold text-gray-500 hover:text-yellow">{item.mobileNumber}</p>
          </Row>
        </div>
      </div>
    ))}
  </div>
</div>
    </>
  );
};

export default LatestListing;
