import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Input as AntdInput, Row, message } from "antd";
import { Link, useNavigate,useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import { AuthContext } from "./AuthContext";
import { sendOTP, verifyOTP } from "../../utils/API";
import logo from "../../assets/backendassets/logo.png";

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [verifyLoading , setVerifyLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const onFinishStep1 = (values: any) => {
    setIsLoading(true);
    sendOTP(values)
      .then((res: any) => {
        if (res.status === 201) {
          setMobile(values.mobileNo);
          setStep(2);
          message.success("OTP sent successfully!");
        }
      })
      .catch((error: any) => {
        message.error(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFinishStep2 = () => {
    setVerifyLoading(true);
  
    if (!otp) {
      message.error("Please enter OTP");
      setVerifyLoading(false); // Stop the loading indicator if OTP is not entered
      return;
    }
  
    verifyOTP({ mobile, otp })
      .then((res: any) => {
        if (res.status === 200) {
          setAuthData({
            token: res.data.token,
            roleId: res.data.user.roleId,
            user: res.data.user,
          });
          localStorage.setItem("token", res.data.token);
  
          // Redirect to the desired location or default page
          const redirectPath = location.state?.from || (res.data.user.roleId === 1 ? "/backend/dashboard" : "/");
          navigate(redirectPath); // Navigate to the original page or fallback
          message.success("Login successfully!");
        }
      })
      .catch((error: any) => {
        message.error(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  useEffect(() => {
    // Clear local storage when the component mounts
    localStorage.clear();
  }, []);


  const resendOTP = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);
    sendOTP({ mobileNo: mobile })
      .then((res: any) => {
  
          message.success(res.data.message);
      })
      .catch((error: any) => {
        message.error(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = () => {
    setStep(1);
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <Row align="middle" className="w-full max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
        <Col span={12} className="hidden lg:block">
          <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/login_banner.svg" alt="Login Banner" className="object-cover w-full h-full" />
        </Col>
        <Col span={24} lg={12} className="p-8">
          <div className="flex justify-center mb-8">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-20" />
            </Link>
          </div>
          {step === 1 ? (
            <Form initialValues={{ remember: true }} onFinish={onFinishStep1} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
                <Form.Item
                  name="mobileNo"
                  initialValue={mobile}
                  rules={[
                    { required: true, message: "Please enter your mobile number!" },
                    { pattern: /^\d{10}$/, message: 'Please enter a valid 10-digit mobile number' }
                  ]}
                >
                  <AntdInput placeholder="Enter Mobile Number" className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                </Form.Item>
              </div>
              <Form.Item>
                <button
                  type="submit"
                  className={`w-full py-3 px-4 text-white font-semibold rounded-md ${isLoading ? 'bg-yellow-700' : 'bg-yellow-700'} hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending OTP...' : 'Send OTP'}
                </button>
              </Form.Item>
            </Form>
          ) : (
            <div className="space-y-6">
              <div className="text-center">
                <p className="text-gray-600">Login for a seamless experience</p>
              </div>
              <div className="text-center">
                <p className="text-gray-700 text-lg font-semibold">{`+91 ${mobile}`}</p>
                <button onClick={handleEdit} className="text-blue-500 hover:underline focus:outline-none">Edit</button>
              </div>
              <Form initialValues={{ remember: true }} onFinish={onFinishStep2} className="space-y-6">
                <div className="text-center">
                  <label className="block text-sm font-medium text-gray-700 pb-3">Enter OTP</label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span className="mx-1" />}
                    renderInput={(props) => <input {...props} className="w-12 h-12 text-center border rounded-md" />}
                    inputStyle={{ width: '100%', padding: '8px' }}
                  />
                </div>
                <Form.Item>
                  <button
                    type="submit"
                    className={`w-full py-3 px-4 text-white font-semibold rounded-md ${isLoading ? 'bg-yellow-700' : 'bg-yellow-700'} hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out`}
                    disabled={verifyLoading}
                  >
                    {verifyLoading ? 'Verifying...' : 'Verify'}
                  </button>
                </Form.Item>
                <div className="text-center text-green-600">
                  <p>✔ Verification OTP sent to +91 {mobile}</p>
                </div>
                <div className="text-center">
                  <p className="text-gray-500">Didn't receive the OTP? <button onClick={resendOTP} className="text-blue-500 hover:underline focus:outline-none">Resend OTP</button></p>
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
