import Login from "../Pages/Auth/Login";
import AddListing from "../Pages/frontend/Listing/business/AddListing";
import Contact from "../Pages/frontend/ContactUs/ContactUs"
import AboutUs from "../Pages/frontend/Aboutsus/AboutUs";
import DashboardUser from "../Pages/frontend/Home/Dashboard";
import Pricing from "../Pages/frontend/Pricing/pricing";
import Success from "../Pages/frontend/Listing/Success";
import BusinessDetail from "../Pages/frontend/Business list/BusinessDetail";
import { Navigate, useLocation } from "react-router-dom";
import { useUserRole } from "./useUserRole";
import BusinessListings from "../Pages/frontend/Business list/BusinessListing";
import MyBusinesses from "../Pages/frontend/Business list/MyBusiness";

import EditListing from "../Pages/frontend/Listing/business/Edit";
import Mlogin from "../Pages/frontend/Auth/Login";
import Template from "../Pages/common/Template";
import EditPage from "../Pages/frontend/Listing/business/Editimage"
import SecurityAndPrivacy from "../Pages/frontend/Help";

const withRoleBasedComponent = (Component : any, allowedRoles : any ) => {
  return (props : any ) => {
    const roleId = useUserRole();
    const location = useLocation(); 
    if (allowedRoles.includes(roleId)) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/mlm/login" state={{ from: location }} />;
    }
  };
};


const frontendRoutes = [

  {
    path: "/login",
    name: "Login",
    component: Login,
    exact: true,
  },
  {
    path: "/mlm/login",
    name: "Login",
    component: Mlogin,
    exact: true,
  },
  {
    path: "/",
    name: "Dashboard",
    component: DashboardUser,
    exact: true,
  },
  {
    path: "/listing",
    name: "Listing",
    component : BusinessListings,
    exact: true,
  },
  {
    path: "/my-listing",
    name: "Listing",
    component : withRoleBasedComponent(MyBusinesses, [1, 2]),
    exact: true,
  },
  {
    path : "/business/:id/edit",
    name : "Edit Listing",
    component : withRoleBasedComponent(EditListing, [1, 2]),
  },
  {
    path : "/business/:id/editimage",
    name : "Edit Listing",
    component : withRoleBasedComponent(EditPage, [1, 2]),
  },
  {
    path: "/add-listing",
    name: "AddListing",
    component: withRoleBasedComponent(AddListing, [1, 2]),
    exact: true,
  },  
  {
    path : "/contact-us",
    name :"Contact",
    component : Contact , 
    exact : true
  },
  {
    path : "/pricing",
    name : "Pricing",
    component : Pricing,
    exact : true
  },
  {
    path : "/about-us",
    name :"Aboutus",
    component : AboutUs , 
    exact : true
  },
  {
    path : "/business/success",
    name :"Success",
    component : Success , 
    exact : true
  },
  {
    path : "/business/:slug",
    name :"Business",
    component : BusinessDetail,
    exact : true
  },
  {
    path : "/business/template",
    name :"Business",
    component : Template,
    exact : true
  },
  {
    path : "/domain/:slug",
    name :"Business",
    component : Template,
    exact : true
  },
 
];


export default  frontendRoutes;