import React from "react";
import "./HowWorks.css";
import { Col, Row } from "antd";
import { HiOutlineUsers, HiUsers } from "react-icons/hi";
import { PiNotePencilDuotone } from "react-icons/pi";
import { MdOutlineKeyboardHide } from "react-icons/md";
import { FaChartLine } from "react-icons/fa";
const HowWorks = () => {
  return (
    <>
      <div
        style={{
          backgroundImage:
            "url('https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/bg-09.png')",
          height: "auto",
          width: "100%",
          overflow: "hidden", // Add overflow: hidden to prevent content overflow
    position: "relative", // Ensure position is relative
        }}
        className="bg-yellow-700"
      >
  


        <div className=" pt-12   md:pt-12 md:ms-8 md:px-8 md:pb-12 sm:pb-12 xs:pb-12 pb-12  sm:pt-8 sm:ms-8 sm:px-8 ">
          <p className=" text-lg text-white text-center font-bold "style={{ fontFamily: '"Manrope", sans-serif' }}>Discover how Net4Surge can transform your business and drive success</p>
          <p className="text-white text-5xl  text-center font-bold mt-2" style={{ fontFamily: '"Manrope", sans-serif' }}>
          How Net4Surge Works for Your Business
          </p>
        </div>
    
      <Row
          justify={"space-around"}
          className="text-center  md:pt-8 md:ps-8 md:px-8   xl:pt-12 xl:ps-12 xl:px-12  sm:pt-8 sm:ps-8 sm:px-8 "

        >
          {/* <Col
            className="bg-white  pl-5 shadow-lg d-flex flex-column justify-content-between md:transform md:-skew-x-6 mb-10"
            xs={10} sm={10} md={10} lg={5} xl={5} xxl={5}
            style={{ padding: "40px 36px 40px 20px" }}
          >
            <HiOutlineUsers
              size={50}

              className="m-auto text-yellow"
            />
            <p className="mt-4 text-black font-bold text-xl">
              Create an account
            </p>
            <p className="mt-2 text-gray-500 text-md">
              There are many variations of the passages of available.
            </p>
          </Col> */}
          <Col
            className="bg-white  shadow-lg d-flex flex-column justify-content-between md:transform md:-skew-x-6 mb-10"
            xs={10} sm={10} md={10} lg={5} xl={5} xxl={5}
            style={{ padding: "40px 36px 40px 20px" }}
          >
            <PiNotePencilDuotone
              size={50}

              className="m-auto text-yellow"
            />
            <p className="mt-4 text-black font-bold text-xl"style={{ fontFamily: '"Manrope", sans-serif' }}>Add Listings</p>
            <p className="mt-2 text-gray-500 text-md"style={{ fontFamily: '"Manrope", sans-serif' }}>
            Easily add your business details with our simple and intuitive listing process.
            </p>
          </Col>
          <Col
            className="bg-white  shadow-lg d-flex flex-column justify-content-between md:transform md:-skew-x-6 mb-10"
            xs={10} sm={10} md={10} lg={5} xl={5} xxl={5}
            style={{ padding: "40px 36px 40px 20px" }}
          >
            <MdOutlineKeyboardHide
              size={50}

              className="m-auto text-yellow"
            />
            <p className="mt-4 text-black font-bold text-xl"style={{ fontFamily: '"Manrope", sans-serif' }}>
              Publish Listings
            </p>
            <p className="mt-2 text-gray-500 text-md"style={{ fontFamily: '"Manrope", sans-serif' }}>
            Publish your listings instantly to make your business visible to millions of potential customers.
            </p>
          </Col>
          <Col
            className="bg-white  shadow-lg d-flex flex-column justify-content-between md:transform md:-skew-x-6 mb-10"
            xs={10} sm={10} md={10} lg={5} xl={5} xxl={5}
            style={{ padding: "40px 36px 40px 20px" }}
          >
            <FaChartLine
              size={50}

              className="m-auto text-yellow"
            />
            <p className="mt-4 text-black font-bold text-xl"style={{ fontFamily: '"Manrope", sans-serif' }}>Get Exposure</p>
            <p className="mt-2 text-gray-500 text-md"style={{ fontFamily: '"Manrope", sans-serif' }}>
            Gain significant exposure and connect with a broader audience across the globe.
            </p>
          </Col>
        </Row>
        </div>
    </>
  );
};

export default HowWorks;
