import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CrownOutlined,CheckCircleOutlined, UserOutlined, DollarCircleOutlined, SafetyCertificateOutlined, BookOutlined, GlobalOutlined, StarOutlined } from '@ant-design/icons';
import { Card, Row, Col, Checkbox, Button ,Table} from 'antd'
import { Column } from '@ant-design/plots';

interface Lead {
  month: string;
  leads: number;
}

interface InteriorDesigner {
  rank: number;
  name: string;
  leads: number;
}

interface Data {
  leadsGenerated: Lead[];
  interiorDesigners: InteriorDesigner[];
}

const Success: React.FC = () => {
  const location = useLocation();
  const { state } = location;

  // Extract data passed from the previous page
  const {  businessDetails } = state || {};
  // console.log("Business Details:", businessDetails);
  
  const galleryImages = businessDetails?.galleryImage?.fileList || [];
  const simpleImageArray = businessDetails?.galleryImage || [];

  // Determine the number of images
  const imageCount =
    Array.isArray(galleryImages) && galleryImages.length > 0
      ? galleryImages.length
      : Array.isArray(simpleImageArray)
      ? simpleImageArray.length
      : 0;

  // Calculate the completion percentage
  const completionPercentage = Math.min((imageCount / 4) * 100, 100); // Cap at 100%


  const initialData: Data = {
    leadsGenerated: [
      { month: "Mar '24", leads: 4283 },
      { month: "Apr '24", leads: 6970 },
      { month: "May '24", leads: 5371 },
    ],
    interiorDesigners: [
      { rank: 1, name: "A K Enterprises", leads: 37 },
      { rank: 2, name: "Maplex Interiors", leads: 22 },
      { rank: 3, name: "Apno Contractor", leads: 11 },
      { rank: 4, name: "Shri Siddhivinayak Interiors", leads: 9 },
      
    ],
  };

  const [data, setData] = useState<Data>(initialData);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/backend/pricing-plan');
    
     // Replace '/premium-plan' with the desired route
  };
  const handleRedirect1 = () => {
    navigate('/business/:id/edit');
  }
  const barConfig = {
    data: data.leadsGenerated,
    xField: 'month',
    yField: 'leads',
    seriesField: 'month',
    theme: {
      colors10: ['#fbca25'],
    },
    barWidthRatio: 0.8,
    intervalPadding: 0,
    xAxis: {
      title: {
        text: 'Month',
      },
    },
    yAxis: {
      label: null,
      grid: null,
    },
    
    legend: {
      position: 'top-left',
    },
  };

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '20%',
    },
    {
      title: 'Business Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Leads',
      dataIndex: 'leads',
      key: 'leads',
      width: '20%',
    },
  ];

  return (
    <div className=" mt-28 lg:px-0 lg:ps-0 xl:px-36 xl:ps-36 md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
      <div className="text-center mb-6">
      <h2 className="text-2xl">
          <CheckCircleOutlined className="text-green-500 mr-2" />
          <span className="text-2xl font-bold" > Congratulations ! </span>
            Your business is now registered with Net4Surge
        </h2></div>
        <hr
        className="text-black"
        style={{
          marginTop: " 30px",
          marginBottom: "30px",
        }}
      />
      
      <h2 className="text-xl font-extrabold mb-3">Get Ahead of Your Competition</h2>

      <Row gutter={16}>
        <Col span={24} sm={12}>
          <Card title="Leads Generated in Interior Designers" className="h-full flex flex-col  text-center shadow-lg">
            <div className="flex-grow">
              <h2 className="text-4xl font-bold mb-4">{data.leadsGenerated.reduce((acc, cur) => acc + cur.leads, 0)}</h2>
              <div style={{ height: '250px' }}>
  <Column {...barConfig} />
</div>
            </div>
            <Button type="primary" className="self-center h-10 text-black font-bold mt-6 bg-yellow-700 ">Get Leads with Buyer Details</Button>
          </Card>
        </Col>
        <Col span={24} sm={12} className=''>
          <Card title="Interior Designers Leaderboard in Mumbai" className="h-full flex flex-col  text-center shadow-lg">
            <div className="flex-grow ">
              <Table
                dataSource={data.interiorDesigners}
                columns={columns}
                pagination={false}
                rowKey="rank"
              />
            </div>
            <Button type="primary" className="self-center h-10 text-black font-bold  mt-6 bg-yellow-700">Get Ahead of Your Competitor</Button>
          </Card>
        </Col>
      </Row>
      <div className="mt-8">
  <Card className="text-center">
    <h1 className="flex items-center text-xl font-bold mb-2">
      <span className="bg-yellow-400 w-12 h-12 rounded-full p-2 mr-4 flex items-center justify-center">
        <CrownOutlined style={{ fontSize: '30px', color: 'white' }} />
      </span>
      Upgrade your Free Listing Plan
    </h1>
    <p className='flex items-center'>Get more visibility , get more customers to contact you!</p>
    <Row gutter={32} className="justify-around mt-3 m-0 sm:m-4 ">
      <Col span={24} sm={10}>
        <ul className="space-y-2 mb-4">
          <li className="flex items-center">
            <span className="bg-yellow-400 w-8 h-8 rounded-full flex items-center justify-center mr-2">
              <StarOutlined style={{ color: 'white' }} />
            </span>
            Get Higher Visibility than Free Listing
            <Checkbox checked className="ml-auto accent-yellow-700" />
          </li>
          <li className="flex items-center">
            <span className="bg-yellow-400 w-8 h-8 rounded-full flex items-center justify-center mr-2">
              <UserOutlined style={{ color: 'white' }} />
            </span>
            Build your strong Social Media Presence
            <Checkbox checked className="ml-auto accent-yellow-700" />
          </li>
          <li className="flex items-center">
            <span className="bg-yellow-400 w-8 h-8 rounded-full flex items-center justify-center mr-2">
              <DollarCircleOutlined style={{ color: 'white' }} />
            </span>
            Get your Business Consulting Report
            <Checkbox checked className="ml-auto" />
          </li>
        </ul>
      </Col>
      <Col span={24} sm={10}>
        <ul className="space-y-2 mb-4">
          <li className="flex items-center">
            <span className="bg-yellow-400 w-8 h-8 rounded-full flex items-center justify-center mr-2">
              <SafetyCertificateOutlined style={{ color: 'white' }} />
            </span>
            Get N4S Verified Trust Seal and Stamp
            <Checkbox checked className="ml-auto" />
          </li>
          <li className="flex items-center">
            <span className="bg-yellow-400 w-8 h-8 rounded-full flex items-center justify-center mr-2">
              <BookOutlined style={{ color: 'white' }} />
            </span>
            Digital Catalogue
            <Checkbox checked className="ml-auto" />
          </li>
          <li className="flex items-center">
            <span className="bg-yellow-400 w-8 h-8 rounded-full flex items-center justify-center mr-2">
              <GlobalOutlined style={{ color: 'white' }} />
            </span>
            Get your Own Business Website with SEO
            <Checkbox checked className="ml-auto" />
          </li>
        </ul>
      </Col>
    </Row>
    <Button
      type="primary"
      className="mt-4 h-10 text-black font-bold bg-yellow-700"
      onClick={handleRedirect}
    >
      Get Premium Plan
    </Button>
  </Card>
</div>


      <div className="mt-8">
  <Card>
    <Row align="middle" justify="center">
      <Col span={24} sm={3} className="text-center">
        <div className="flex justify-center items-center">
          <div className="flex items-center justify-center w-24 h-24 rounded-full border-8 border-green-700">
          <h2 className="text-2xl font-bold m-0">{completionPercentage}%</h2>
          </div>
        </div>
      </Col>
      <Col span={24} sm={21} className="text-left ">
        <h1 className='text-2xl font-bold'>Your Current Business Profile Score</h1>
        <p className='mt-2'>Increase your profile score and reach out to more users on Net4Surge </p>
        <Button type="primary"
         className="mt-3 h-10 text-black font-bold bg-yellow-700"
         onClick={handleRedirect1}
         >
          Click Here to Increase Your Score</Button>

      </Col>
    </Row>
  </Card>
</div>
    </div>
  );
};

export default Success;
