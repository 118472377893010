import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, AutoComplete, message } from "antd";
import backgroundImage from "../../../assets/frontendassets/Frame.jpg";
import { SearchOutlined } from "@ant-design/icons";
import { getAllcategories } from "../../../utils/API";
import { useNavigate } from "react-router-dom";
import statesData from "./states.json"; // Import JSON data here  

interface StateOption {
  label: string;
  value: string;
}

const HomeBanner = () => {
  const placeholderStyle = {
    fontSize: "15px",
    padding: "5px",
    justifyContent: "center",
    color: "#C0C0C0",
    fontWeight: "lighter",
  };

  const [options, setOptions] = useState<any[]>([]);
  const [states] = useState<StateOption[]>(statesData.map(state => ({ label: state.label, value: state.label }))); // Use full state name for value
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState<string | null>(null); // New state
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null); // New state
  const navigate = useNavigate();

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await getAllcategories();
      const formattedOptions = response.filter((category: any) => category.isHome === true && !category.categoryId && !category.isDeleted).map((category: any) => ({
        value: category.name,
        key: category._id
      }));
      setOptions(formattedOptions);
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onSelectState = (value: string) => {
    setSelectedState(value); // Update selected state 
  };

  const onSelectCategory = (value: any, option: any) => {
    setSelectedCategory(option.key); // Update selected category
  };

  const handleSearch = () => {
    navigate(`/listing?category=${selectedCategory || ''}&state=${selectedState || ''}`);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <style>
        {`@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap');`}
      </style>
      <Row
        className="mt-10 p-4 relative"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'center',
        }}
      >
        <div
          className="absolute inset-0 bg-yellow-400 opacity-25"
          style={{ zIndex: 1 }}
        ></div>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={16}
          xxl={16}
          style={{ zIndex: 2 }}
        >
          <div className="p-3 mt-3 ml-3">
            <div
              className="mt-10 text-3xl md:text-5xl xl:text-5xl xxl:text-5xl lg:text-5xl font-semibold text-black"
              style={{ fontFamily: "Manrope" }} >
              Welcome to Net4Surge
            </div>
            <div
              className="mt-3 text-2xl md:text-4xl xl:text-4xl xxl:text-4xl lg:text-4xl font-extrabold text-black"
              style={{ fontFamily: "Manrope" }}
            >
              Your Premier Business Listing Platform
            </div>
            <div>
              <p className="mt-4 text-base md:text-lg text-black"style={{ fontFamily: '"Manrope", sans-serif' }}>
                We believe in the power of Visibility and Connectivity. Our
                platform is designed to connect businesses globally with their
                ideal customers by providing comprehensive, accurate, and
                up-to-date business listings.
              </p>
            </div>
          </div>
          <div>
            <Form
              style={{ backgroundColor: "white", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              className="mt-5 mb-10 p-4"
            >
              <Row className="p-3 rounded-lg" justify="space-between">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  xxl={8}
                  lg={24}
                  xl={8}
                  className="flex mb-3 w-full justify-between items-center"
                  style={{ flex: 1, marginRight: "20px" }}
                >
                   <AutoComplete
                    options={states}
                    bordered={false}
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(inputValue, option) =>
                      option ? option.value.toLowerCase().includes(inputValue.toLowerCase()) : false
                    }
                    placeholder="Select a state" className=""
                    onSelect={onSelectState}
                  >{}
                    <Input
                   
                      suffix={
                        <SearchOutlined
                          // onClick={handleSearch}
                          className="cursor-pointer"
                          style={{ fontSize: "20px",display: "none", color: "#999" }}
                        />
                      }
                      style={{
                        borderRadius: "8px",
                        height: "52px",
                        border: "1px solid gray",
                        paddingLeft: "10px",
                        paddingBottom: "10px",
                        textAlign: "center" // Center text
                      }}
                    />
                  </AutoComplete>
                </Col>
                <Col
                  xs={0}
                  sm={0}
                  md={0}
                  xxl={1}
                  lg={0}
                  xl={1}
                  className="border-l border-gray-300"
                ></Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={14}
                  className="flex mb-3 w-full items-center"
                  style={{ flex: 1, marginLeft: "0px" }}
                >
                  <AutoComplete
                    options={options}
                    bordered={false}
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(inputValue, option) =>
                      option ? option.value.toLowerCase().includes(inputValue.toLowerCase()) : false
                    }
                    placeholder="Search Categories"
                    onChange={onSelectCategory}
                  >{}
                    <Input
                      onKeyDown={handleKeyDown}
                      suffix={
                        <SearchOutlined
                          onClick={handleSearch}
                          className="cursor-pointer pt-1"
                       
                          style={{ fontSize: "20px", color: "#999" }}
                        />
                      }
                      style={{
                        borderRadius: "8px",
                        paddingBottom: "10px",
                        height: "52px",
                        border: "1px solid gray",
                        paddingLeft: "10px",
                        textAlign: "center" // Center text
                      }}
                    />
                  </AutoComplete>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomeBanner;
