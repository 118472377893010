import { Card, Row, Table, Col, Input, message, Spin, Modal } from "antd";
import { AiFillDelete, AiOutlineExport, AiOutlineImport } from "react-icons/ai";
import { RiAddBoxFill, RiImageEditFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { deletecategory, getAllcategories } from "../../../utils/API";
import { FaEdit } from "react-icons/fa";
import * as XLSX from "xlsx";
import React from "react";

interface Category {
  id: string;
  _id: string;
  name: string;
  categoryId: string | null;
}

export const Categorytable = () => {
  const [datasouce, setDatasouce] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(0);
  const [deleteId, setDeleteId] = useState<any | null>(null);
  const [searchText, setSearchText] = useState(""); // Step 1: Add searchText state
  const navigate = useNavigate();

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await getAllcategories();
      setDatasouce(response);
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Step 2: Filter the data based on the search query
  const filteredCategories = datasouce.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase()) // Case-insensitive search
  );

  const handleEditRedirect = (record: any) => {
    navigate(`/backend/category/${record._id}/edit`);
  };

  const handleExport = () => {
    const dataToExport = datasouce.map((item) => ({ Name: item.name }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Categories");
    XLSX.writeFile(workbook, "categories.xlsx");
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        await deletecategory(deleteId);
        message.success("Category deleted successfully");
        fetchCategories();
      } catch (error) {
        message.error("Failed to delete category");
      } finally {
        setDeleteId(null);
      }
    }
  };

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Category Name
        </div>
      ),
      dataIndex: "name",
      render: (text: string) => text || "-",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Action
        </div>
      ),
      dataIndex: "action",
      render: (text: any, record: any) => (
        <div className="d-flex">
          <FaEdit
            className="me-4 text-yellow"
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className="text-yellow"
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => handleDeleteConfirmation(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Category</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col>
            <Col xs={36} sm={36} md={18} xl={12} xxl={12}>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end"
                placeholder="Search..."
                allowClear
                value={searchText} // Bind the input value to searchText
                onChange={(e) => setSearchText(e.target.value)} // Step 3: Update searchText on input change
              />
            </Col>
            <Col xs={24} sm={24} md={6} xl={5} xxl={5}>
              <Link to={"/backend/categories/add"}>
                <button className="btn  d-flex justify-content-center align-items-center w-100 bg-yellow-700 text-black" style={{ color: "white" }}>
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Category</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Card className="container w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table columns={columns} dataSource={filteredCategories} /> {/* Step 4: Use filtered categories */}
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        onOk={handleDelete}
        onCancel={() => setDeleteId(null)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this category?</p>
      </Modal>
    </div>
  );
};
