import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Carousel } from 'antd';
import { FaArrowLeft, FaArrowRight, FaRegComments } from "react-icons/fa";
import { MdDiscount } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi";
import image1 from "../../../assets/frontendassets/websiteimages/Transform Your Business with These 2024 Trends (1).jpg";
import image2 from "../../../assets/frontendassets/websiteimages/HowtoSuperchargeYourBusinessGrowthwithNet4Surge(1).jpg";
import image3 from "../../../assets/frontendassets/websiteimages/The Ultimate Guide to Effective Digital Marketing in 2024 (1).jpg";

const Blog = () => {
  const [slidesToShow, setSlidesToShow] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Array of blog post objects
  const blogPosts = [
    {
      id: 1,
      imageUrl:image1 ,
      date: "27 march",
      author: "Admin",
      comments: 0,
      title: "Transform Your Business with These 2024 Trends",
      content: "Ready to elevate your business in 2024? Discover the latest trends that are shaping the future of commerce. From innovative technologies to evolving consumer behaviors, these trends will help you stay ahead of the competition and achieve remarkable growth ",
      category: "Businesses"
    },
    {
      id: 1,
      imageUrl: image2,
      date: "27 march",
      author: "Admin",
      comments: 0,
      title: "How to Supercharge Your Business Growth with Net4Surge",
      content: "Looking for a game-changing platform to accelerate your business growth? Discover how Net4Surge can help you achieve your business goals with ease. Our platform offers a suite of powerful tools designed to enhance visibility, credibility, and connectivity.",
      category: "Businesses"
    },

    {
      id: 1,
      imageUrl: image3,
      date: "27 march",
      author: "Admin",
      comments: 0,
      title: "The Ultimate Guide to Effective Digital Marketing in 2024",
      content: "Digital marketing is evolving rapidly, and staying ahead of the curve is crucial for success. Dive into our ultimate guide to the most effective digital marketing strategies for 2024 and learn how to leverage them for maximum impact.",
      category: "Businesses"
    },
  ];

  return (
    <Row className="md:pt-8 md:ps-8 md:px-8 xl:pt-16 xl:ps-48 xl:px-48 sm:pt-8 pb-4 sm:ps-8 sm:px-8 sm:pb-8">
      <Col span={24} className='text-center'>
        <p className='font-bold text-md tracking-widest m-3 text-yellow' >FROM THE BLOG</p>
        <p className='text-3xl md:text-3xl lg:text-5xl font-extrabold'>Latest news & articles from the blog</p>
      </Col>
      <Col span={24} className="md:pt-12 md:pb-12">
        <Carousel dots={false} autoplay autoplaySpeed={3000} slidesToShow={slidesToShow} draggable prevArrow={<FaArrowLeft size={20}  className='text-yellow' />} nextArrow={<FaArrowRight />}>
        {
          blogPosts.map((post) => (
            <>
<Col span={24} className="m-2 rounded-lg">
      <div >
      <a href="#">
              <div className="relative">
                <a href="#">
                  <img
                    className="rounded-md  w-full"
                    src={post.imageUrl}
                    alt="Sunset in the mountains"
                  />
                  <div className="rounded-md hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                </a>
                <div className="text-sm absolute top-0 right-0 bg-yellow-700 text-white m-2 pt-1 pb-1 pr-2 pl-2 rounded-md flex flex-row items-center justify-center mt-3 ml-3 hover:text-indigo-600 transition duration-500 ease-in-out">
                  <small className="font-bold">{post.date}</small>
                </div>
              </div>
            </a>
        <Row style={{ backgroundColor : "#f1f1f1" }}  className='rounded-md mt-2 min-h-[300px]'>
          <div className='pl-4 pr-4 '>
          <p className="text-2xl font-bold text-black pt-2" style={{ fontFamily: '"Manrope", sans-serif' }}>
    {post.title}
</p>

            <p className='text-md font-semibold text-gray-500 pt-2' style={{ fontFamily: '"Manrope", sans-serif' }}> {post.content} </p>
          </div>
        </Row>
        
      </div>
    </Col>
            </> )
          ) 
      }
        </Carousel>
      </Col>
    </Row>
  );
};

export default Blog;
