import { Badge, Carousel, Col, Row } from "antd";
import React, { useState, useEffect } from 'react';
import { BsCameraReelsFill } from "react-icons/bs";
import {
  FaBiking,
  FaCamera,
  FaHeart,
  FaLocationArrow,
  FaPhoneSquare,
  FaStar,
} from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getAllBusiness, getHomeFeaturedBusiness } from "../../../utils/API";
import WebsiteSeo from "../Home/WebsiteSeo"
const listings = [
  {
    image: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/listing-8-560x370.jpg",
    logo: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/logo-listing-8.jpg",
    status: "Open",
    featured: true,
    category: "Fitness",
    reviews: 3,
    rating: 3,
    title: "Shape gym training",
    description: "Best Traveling in the UK",
    location: "California",
    price: "$100",
    cameraCount: 5,
  },
  {
    image: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/listing-4-560x370.jpg",
    logo: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/logo-listing-3.jpg",
    status: "Open",
    featured: true,
    category: "Fitness",
    reviews: 3,
    rating: 3,
    title: "Shape gym training",
    description: "Best Traveling in the UK",
    location: "California",
    price: "$100",
    cameraCount: 5,
  },
  {
    image: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/listing-7-560x370.jpg",
    logo: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/logo-listing-7.jpg",
    status: "Open",
    featured: true,
    category: "Fitness",
    reviews: 3,
    rating: 3,
    title: "Shape gym training",
    description: "Best Traveling in the UK",
    location: "California",
    price: "$100",
    cameraCount: 5,
  }
];

const FeaturedListing = () => {
  const [slidesToShow, setSlidesToShow] = useState(2);
  const [businessdata, setBusinessData] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/businesse/';
  const navigate = useNavigate();

  const handleGetBusiness = async () => {
    try {
      const res = await getHomeFeaturedBusiness();
      const filltredBusiness = res.data.filter((item: any) => item.isFeatured == 1);
      setBusinessData(filltredBusiness);
    } catch (error) {
      console.error("Failed to fetch business data", error);
    }
  };

  const handlenavigate = (slug: any) => {
    navigate(`/business/${slug}`);
  };


  useEffect(() => {
    handleGetBusiness();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <WebsiteSeo />
      <Row className="md:pt-8 md:ps-8 md:px-8 xl:pt-12 xl:ps-12 xl:px-12 p-6 sm:pt-8 sm:ps-8 sm:px-8" justify={"center"}>
        <Col span={24}>
          <p style={{ fontSize: "14px", letterSpacing: "1px" }} className="text-center font-bold pb-3 text-yellow">
            LOCAL BUSINESSES
          </p>
          <p style={{ letterSpacing: "-1px" }} className="text-center text-5xl font-bold text-black">
            See Featured listings
          </p>
        </Col>
      </Row>

      <Carousel
        autoplay
        slidesToShow={slidesToShow}
        dots={false}
        draggable
        className="md:pl-12 md:pt-12 md:pr-12 md:pb-12 xl:pt-12 xl:pr-36 xl:px-12 xl:pl-36 xs:pt-6 xs:ps-6 xs:px-6 xs:pl-6 sm:pt-4 sm:ps-4 sm:px-4 sm:pb-8 bg-white" // Add sm:pb-8 for bottom margin in mobile view
      >
        {businessdata.map((listing: any, index: any) => (
          <div className="pl-6 pr-6 md:pb-12 sm:pb-8 bg-white" key={index}> {/* Add sm:pb-8 here as well */}
            <div className="rounded overflow-hidden shadow-lg">
              <div className="relative">
                <img className="w-full" src={imageUrl + listing.galleryImage[0]} alt="Listing" style={{ height: "293px" }} />
                <div className="absolute top-4 right-4 w-12 h-12 text-white text-sm">
                  {/* <img src={listing.logo} className="rounded-full border-spacing-2 p-2 bg-white" alt="Logo" /> */}
                </div>
                <div className="text-sm absolute top-0 left-0 text-white m-2 pt-1 pb-1 pr-2 pl-2 rounded-md flex flex-row items-center justify-center mt-3 ml-3 transition duration-500 ease-in-out">
                  <div className="block sm:inline mt-1">
                    {listing.isVerified === 2 && (
                      <Badge className="text-md font rounded-md pl-2 ml-2 pr-1 px-2 py-1 bg-green-300 text-black">
                        Verified
                      </Badge>
                    )}
                    {listing.isTrusted === 2 && (
                      <Badge className="text-md font rounded-md pl-2 py-1 ml-2 pr-1 px-2 bg-yellow-200 text-black">
                        Trusted
                      </Badge>
                    )}
                  </div>
                </div>
                {listing.featured && (
                  <div className="text-sm absolute top-0 left-12 bg-cyan-600 text-white pt-1 pb-1 pr-2 pl-2 rounded-md flex flex-row items-center justify-center mt-3 ml-3 transition duration-500 ease-in-out">
                    <small className="font-bold">Featured</small>
                  </div>
                )}
                <div className="hover:bg-white hover:text-black rounded-lg text-white bg-opacity-25 bg-black hover:animate-fade absolute top-24 w-full left-0">
                  <div className="hover:bg-white hover:text-black transition-colors duration-300 p-4 rounded-lg">
                    <Row className="d-flex mb-3" align={"middle"}>
                      {[...Array(3)].map((_, i) => (
                        <FaStar key={i} className="mr-1" size={15} color="yellow" />
                      ))}
                      {[...Array(2)].map((_, i) => (
                        <FaStar key={i} className="mr-1" size={15} color="silver" />
                      ))}
                      <p className="text-xs">(3 Reviews)</p>
                      <div className="block sm:inline">
                        <p className="text-md font rounded-md ml-2 pr-1 px-2 py-1">
                          Verified
                        </p>
                      </div>
                    </Row>
                    <Col span={24} className="font-bold  text-lg" style={{ fontSize: "22px" }}>
                      {listing.businessName}
                    </Col>
                    <Col span={24} className="font-lighter text-sm mb-4">
                      {listing.mobileNumber}
                    </Col>

                    <Row className="d-flex align-items-center mt-[-12px]">
                      <Col
                        xs={2}
                        sm={2}
                        md={1}
                        lg={1}
                        xl={1}
                        xxl={1}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <IoLocationSharp
                          className="text-yellow"
                          size={32} // Default size
                          style={{
                            fontSize: '48px', // Explicit size for desktop view
                          }}
                        />
                      </Col>
                  
                      <Col
                        xs={10}
                        sm={10}
                        md={23}
                        lg={23}
                        xl={23}
                        xxl={23}
                        className="d-flex align-items-center"
                      >
                        <p
                          className="hover:text-black ml-2"
                          style={{ wordWrap: 'break-word', maxWidth: '100%' }}
                        >
                          {`${listing.buildingName}, ${listing.streetName}, ${listing.city}, ${listing.state}, ${listing.pincode}`}
                        </p>
                      </Col>
                    </Row>



                    <hr className="m-2 text-gray-500" style={{ width: "85%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>

    </>
  );
};

export default FeaturedListing;
