import { Row , Col } from 'antd'
import React from 'react'
import "./PopularLocation.css"
import { BiRightArrow } from 'react-icons/bi'
import { IoIosArrowRoundForward } from 'react-icons/io'
import image1 from "../../../assets/frontendassets/websiteimages/SECTION7australia.jpg";
import image2 from "../../../assets/frontendassets/websiteimages/SECTION7Usa.jpg";
import image3 from "../../../assets/frontendassets/websiteimages/SECTION7dubai.jpg";
import image4 from "../../../assets/frontendassets/websiteimages/SECTION7canda.jpg";
import image5 from "../../../assets/frontendassets/websiteimages/SECTION7united kingdom.jpg";
const PopularLocation = () => {
  return (
    <>
   <Row className="pt-4 md:pl-12 md:pt-12 md:pr-12 md:pb-4 justify-between xl:pl-24 xl:pt-24 xl:pr-24 xl:pb-4 sm:pl-6 sm:pt-6 sm:pr-6 sm:pb-8">
    <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10} className='p-2 md:p-0'>
        <p className='font-bold text-md tracking-widest text-yellow' style={{ fontFamily: '"Manrope", sans-serif' }} >
            Expanding Globally Soon!
        </p>
        <p className='text-4xl sm:text-3xl font-extrabold tracking-tight mt-4' style={{ fontFamily: '"Manrope", sans-serif' }}>
            We are thrilled to announce that we will be expanding our reach to major global markets very soon. Get ready to connect and grow your business in:
        </p>
    </Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={10}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ height: "260px", marginBottom: "17px" }} className="">
                <div className="wrapper">
                    <div className="card">
                        <div className="location-info ">
                            <p className="relative text-blue-50 py-1 px-4 rounded"></p>
                            <p className='text-white text-2xl font-bold' style={{ fontFamily: '"Manrope", sans-serif' }}>Australia</p>
                            <p className='text-white'>Australia</p>
                        </div>
                        <img src={image1} className='rounded-md' alt=""/>
                        <div className="info">
                            <IoIosArrowRoundForward className='text-yellow rounded-full p-1 absolute z-1' style={{ bottom: "-162px", height: "50px", width: "50px", left: "120px", backgroundColor: "white" }} />
                            <button>
                                <span className='text-2xl p-2 text-white'>Australia</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ height: "260px", marginBottom: "17px" }} className="">
                <div className="wrapper">
                    <div className="card">
                        <div className="location-info ">
                            <p className="relative text-blue-50 py-1 px-4 rounded"></p>
                            <p className='text-white text-2xl font-bold' style={{ fontFamily: '"Manrope", sans-serif' }}>USA</p>
                            <p className='text-white'>USA</p>
                        </div>
                        <img src={image2} className='rounded-md' alt=""/>
                        <div className="info">
                            <IoIosArrowRoundForward className='text-yellow rounded-full p-1 absolute z-1' style={{ bottom: "-162px", height: "50px", width: "50px", left: "120px", backgroundColor: "white" }} />
                            <button>
                                <span className='text-2xl p-2 text-white' style={{ fontFamily: '"Manrope", sans-serif' }}>USA</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </Col>
</Row>

<Row className="md:pl-12 md:pt-4 md:pr-12 md:pb-16 justify-between xl:pl-24 xl:pt-4 xl:pr-24 xl:pb-36 sm:pl-6 sm:pt-6 sm:pr-6 sm:pb-8">
    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ height: "260px", marginBottom: "17px" }}>
        <div className="wrapper">
            <div className="card">
                <div className="location-info">
                    <p className="relative text-blue-50 py-1 px-4 rounded"></p>
                    <p className='text-white text-2xl font-bold mt-4' style={{ fontFamily: '"Manrope", sans-serif' }}>Dubai</p>
                    <p className='text-white'>Dubai</p>
                </div>
                <img src={image3} className='rounded-md' alt=""/>
                <div className="info">
                    <IoIosArrowRoundForward className='text-yellow rounded-full p-1 absolute z-1' style={{ bottom: "-162px", height: "50px", width: "50px", left: "120px", backgroundColor: "white" }} />
                    <button>
                        <span className='text-2xl p-2 text-white'>Dubai</span>
                    </button>
                </div>
            </div>
        </div>
    </Col>
    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ height: "260px", marginBottom: "17px" }}>
        <div className="wrapper">
            <div className="card">
                <div className="location-info">
                    <p className="relative text-blue-50 py-1 px-4 rounded"></p>
                    <p className='text-white text-2xl font-bold' style={{ fontFamily: '"Manrope", sans-serif' }}>Canada</p>
                    <p className='text-white'>Canada</p>
                </div>
                <img src={image4} className='rounded-md' alt=""/>
                <div className="info relative">
                    <button className="w-full relative flex justify-items-start">
                        <div>
                            <span className="text-2xl p-2 text-white">Canada</span>
                        </div>
                        <IoIosArrowRoundForward className="text-yellow rounded-full p-1 absolute right-0 transform -translate-y-1/2 z-10" style={{ height: "50px", width: "50px", top: "-1px", backgroundColor: "white" }} />
                    </button>
                </div>
            </div>
        </div>
    </Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ height: "260px", marginBottom: "17px" }}>
        <div className="wrapper">
            <div className="card">
                <div className="location-info">
                    <p className="relative text-blue-50 py-1 px-4 rounded"></p>
                    <p className='text-white text-2xl font-bold' style={{ fontFamily: '"Manrope", sans-serif' }}>United Kingdom</p>
                    <p className='text-white'>United Kingdom</p>
                </div>
                <img src={image5} className='rounded-md' alt=""/>
                <div className="info relative">
                    <button className="w-full relative flex justify-between items-center">
                        <div>
                            <span className="text-2xl p-2 text-white">United Kingdom</span>
                        </div>
                        <IoIosArrowRoundForward className="text-yellow rounded-full p-1 absolute right-0 transform -translate-y-1/2 z-10" style={{ height: "50px", width: "50px", top: "-1px", backgroundColor: "white" }} />
                    </button>
                </div>
            </div>
        </div>
    </Col>
</Row>

     
   
    </>
  )
}

export default PopularLocation