import React, { useEffect, useState } from "react";
import { Row, Col, Card, message, Spin } from "antd";
import { getAllcategories } from "../../../utils/API";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const imageUrl = `${process.env.REACT_APP_IMAGE_URL}/categories/`;
  const navigate = useNavigate();
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await getAllcategories();
      const formattedOptions = response.filter((category : any) => category.isHome === true && !category.categoryId && !category.isDeleted);
      setCategories(formattedOptions.slice(0, 6)); // Limit to 6 categories
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryName : any) => {
    navigate(`/listing?category=${categoryName}&state=`);
  };

  return (
    <div className="md:pt-8 md:ps-8 md:px-8 xl:pt-16 xl:ps-44 xl:px-44 sm:pt-8 sm:ps-8 sm:px-8 xs:pt-8 xs:ps-8 xs:px-8 xs:pl-8">
      <Row>
        <Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={12} className="p-2 md:p-0">
          <div className="font-bold text-yellow" style={{ letterSpacing: "1.5px", fontSize: "14px", marginBottom: "20px", fontFamily: '"Manrope", sans-serif' }}>
            DISCOVER CATEGORIES
          </div>
          <div className="text-5xl xs:text-3xl md:text-3xl lg:text-3xl font-extrabold"style={{ fontFamily: '"Manrope", sans-serif' }}>
            Discover your business more easily by category
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={12} className="w-75 pt-2 sm:pt-5 p-2 md:p-0">
          <div className=" text-lg font-lighter text-black" style={{ letterSpacing: "1.5px", fontSize: "14px",fontFamily: '"Manrope", sans-serif' }}>
            We cover all business categories to help you list your businesses and gain visibility.
            Categories:
          </div>
        </Col>
      </Row>
      <Row className="mt-10 xs:m-10 xs:p-8 sm:p-8 md:p-8 lg:p-8" style={{ backgroundColor: "#f5f5f5" }}>
        {loading ? (
          <Spin size="large" />
        ) : (
          categories.map((category : any, index : any) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className="p-2" key={index}   onClick={() => handleCategoryClick(category._id)}>
              <Card className="p-2 border-gray-200 border-2">
                <div className="d-flex">
                  <img
                    src={imageUrl + category.image}
                    alt={category.name}
                    className="mr-4"
                    style={{ height: "62px", width: "110px", objectFit: "cover", borderRadius: "8px" }}
                  />
                  <Row gutter={16}>
                    <Col span={24} className="font-bold text-lg">
                      {category.name}
                    </Col>
                    <Col span={24}>{category.description}</Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </div>
  );
};

export default Categories;
